.menu-icon-div {
    width: 70%;
    height: 3px;
    background-color: white;
    margin-bottom: 6px;
}

.mobile-menu-icon {
    position: absolute;
    width: 100px;
    text-align: end;
    top: calc(33px / 2);
    right: 2em;
}

.mobile-menu-block{
    display:flex;
    text-align: end;
    cursor: pointer;
}

.mobile-menu-block:hover .menu-icon-div{
    background-color: #0098da;
    transition: background-color .15s;
}
.mobile-menu-block:hover .mobile-menu-title{
    color:#0098da;
    transition: color .15s;
}

.mobile-menu-hamburger{
    flex:3;
}
.mobile-menu-hamburger:hover .menu-icon-div{
    background-color: #0098da;
}
.mobile-menu-title{
    flex:1;
    padding-right: 15px;
}

.link-hover:hover {
    cursor: pointer;
}

@media (min-width: 700px) {
    .mobile-menu-icon {
        top: 36.5px;
    }
}

@media (min-width: 1500px) {
    .mobile-menu-icon {
        display: none;
    }
}