.social-icon-wrapper { 
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.social-icon {
    opacity: 0.6;
}

@media (min-width: 850px) {
    .social-icon-wrapper {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}
