.stick-to-bottom{
    position:fixed;
    bottom:0;
    z-index: 1000;
    width: fit-content;
    width: 100%;
    padding-top: 35px;
    padding-bottom: 35px;
}

.styled-div{
    padding-left:20px;
    padding-right:20px;
}

.ctrlv-blue-button {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    width: 50%;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 1em;
    font-family: 'Days One', sans-serif;
}

.no-stick{
    width: 70%;
}