.location-padding-wrapper{
    padding-left: 2.5%;
    padding-right: 2.5%;
}

.package-gallery  { 
    text-align: center;
    padding-bottom: 1em;
}

.location-section-header {
    font-size: 20px;
    padding-bottom: 1em;
    text-align: center;
}

.location-location-line {
    font-size: 18px;
    color: #0098DA;
    padding-bottom: 1em;
}

.location-location-line a {
    color: #0098DA;
}

.location-hour-row {
    font-size: 18px;
    padding-bottom: 0.25em;
}

.hours-wrapper {
    padding-bottom: 1em;
}

.location-games-wrapper {
    text-align: center;
}

.games-paragraph {
    text-align:left;
}

.location-title {
    font-size: 24px;
    text-align: center;
    padding: 1em;
}

.facility-hours {
    border: none;
}

.google-review-wrapper .review-text {
    padding-top: 1em;
    padding-bottom: 1em;
}

.review:after {
    content: "";
    
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;

    border-top: 1px solid gray;

    display: block;
    width: 50%;
}

.stars {
    padding-right: 2em;
}

.google-review-wrapper {
    font-size: 18px;
    padding: 1em;
    height: 275px;
    overflow-y: scroll;
    border: 1px solid gray;
    border-radius: 20px;
}

.price-wrapper .location-section-header {
    text-align: center;
}

.review-contact-section {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 2em;
}

.location-review-contact-wrapper {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.location-review-wrapper {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
}

.location-parent-request {
    font-size: 18px;

    text-align: center;
}

.section-wrapper {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.section-wrapper .location-details {
    display: inline-block;
    vertical-align: top;
    width: 45vw;
}

.section-wrapper .map-wrapper {    
    display: inline-block;
    width: 45vw;
    height: 355px;
}

.location-game-scroller-wrapper {
    width: 25%;
    margin: auto;
}

.gallery-cell {
    padding-top: 1em;
    position: relative;
    text-align: center;
    display: inline-block;
    vertical-align: bottom;
    width: 20%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.mobile-gallery-cell {
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
}

.calendar-wrapper {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.calendar-frame-helper {
    height: 540px;
}

.calendar-wrapper iframe {
    border: none;
}

.selected-review-tab {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding: 0.5em;
    margin: 1em;
    font-family: 'Days One', sans-serif;
}

.unselected-review-tab {
    background: linear-gradient(0deg, #666666, #949494);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding: 0.5em;
    margin: 1em;
    font-family: 'Days One', sans-serif;
    transition: 1s;
}

.unselected-review-tab:hover { 
    background: linear-gradient(0deg, #00577d, #0098DA);
    cursor: pointer;
}

.review-wrapper {
    background-color: #1e1e1e;
    border-radius: 25px;
    padding: 1em;
}

.review-description {
    padding-bottom: 1em;
}

.location-book-now-button {
    animation: BookNowPulse 3s infinite;
}

.directions-wrapper {
    padding: 1em;
}

.directions-iframe {
    margin-left: auto;
}

.location-faq {
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
}

.location-faq-browser-width {
    width: 50%;
}

.text-padding {
    padding-left: 2em; 
    padding-right: 2em;
}

.anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

.location-game-section-padding {
    padding-bottom: 2em;
}

.pricing-body{
    font-size:18px;
    padding:10px;
}
.pricing-discounts{
    border-radius: 2px;

    font-size: 23px;


    padding:10px;
}
.pricing-discounts-table{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 3px;

    margin: 10px;
}
.pricing-discounts-table FaqSection{
    margin-left: 5px;
}
.pricing-discounts-footer{
    padding: 0.5em;

    font-style: italic;
    color: #999;
}

.padded{
    padding:10px;
    color:white
}

.spanning{
    width:100%;
    text-align: center;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
}
.warning-gradient{
    background: linear-gradient(to bottom, #FFEB3B , #FFC107);
    padding: 30px;

    cursor: pointer;
    color:black;
    text-shadow: none;
}
.warning-gradient:hover{
    background: none;
    background-color: rgb(223, 167, 0);
}

.stick-to-bottom{
    position:fixed;
    bottom:0;
    z-index: 100;
}

.styled-div{
     width: fit-content;
     padding-left:20px;
     padding-right:20px;
}

.atitle {
    font-size: 2.2rem;
    text-decoration-color: white;
    text-align: center;
    width: 100%;
    margin-block-start: 2rem;
    margin-block-end: 2.5rem;
}

.atext {
    font-size: 2rem;
    text-align: center;
    width: 100%;
    margin-block-start: 1rem;
    margin-block-end: 0.75rem;
}

@media (max-width: 1150px) {
    .calendar-wrapper {
        width: 100% !important;
    }
}

@media (max-width: 625px) {
    .anchor {
        top: -60px;
    }
}
@media (max-width: 1000px) {
    .pricing-discounts-table .faq-section{
        width:100%;
    }
    .location-faq-browser-width {
        width: 90%;
    }
    
    .location-review-contact-wrapper {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    
    .gallery-cell {
        position: relative;
        display: block;
        width: auto;
        padding-top: 1em;
        padding-bottom: 1em;
    }

}