.location-map-wrapper {
    height: 80vh;
    width: 90%;
    padding: 1em;
    margin-left: auto;
    margin-right: auto;
    text-shadow: none;
}

.map-wrapper { 
    height: 90%;
}