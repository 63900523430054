.newsletter-wrapper {
    font-size: 18px;
}

.newsletter-wrapper input {

    background: #4a4a4a80;
    width: 100%;
    height: 100%;
    padding: 0;

    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    height: 34px;
    color: white;

    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.newsletter-wrapper input::placeholder {
    color: #858585;
}

.birthday-wrapper input {
    width: 20%;
    margin-right: 1em;
}