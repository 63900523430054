.aimage-slider-wrapper {
    position: relative;
    width:100%;
    height:calc(100vw/3.38);
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom: 2.5rem;
}

.adesktop-image-slider-wrapper {
    background: linear-gradient(to bottom, #000, #000);
    position: relative;
    width: 100%;
    height: 100%;
}

.adesktop-image-slider-wrapper .aimage-slider-image {
    text-align: center;
    background: linear-gradient(to bottom, #000, #000);
    transform: translateX(-50%);
    left:50%;
    position: absolute;
    top: 0px;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.adesktop-image-slider-wrapper .aimage-slider-image-2 {
    text-align: center;
    left:50%;
    transform: translateX(-50%);
    background: linear-gradient(to bottom, #000, #000);
    position: absolute;
    top: 0px;
    object-fit: contain;
    width: 100%;
    height: 100%;
}
@media(max-width:600px){
    .adesktop-image-slider-wrapper .aimage-slider-image {
        object-fit: fill;
        background-color: #000;
    }
    .desktop-image-slider-wrapper .aimage-slider-image-2 {
        object-fit: fill;
        background-color: #000;
    }
}
.aimage-transition-show {
    opacity: 1;
    transition: 1s;
}

.aimage-transition-hide {
    opacity: 0;
    visibility: hidden;
}

.amobile-image-slider-wrapper {
    position: relative;
    overflow: hidden;
}

.amobile-image-slider-wrapper .aimage-slider-image {
    margin-left: 50%;
    transform: translateX(-50%);
    object-fit: fill;
}

.amobile-image-slider-wrapper .aimage-slider-image-2 {
    margin-left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 0px;
    object-fit: fill;
}

.aimage-slider-controls-wrapper {
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%);
}

.aimage-slider-controls-wrapper button {
    display: inline-block;

    text-align: center;
    border-radius: 8px;
    border: none;
    padding: 0.5em;
    margin: 0.25em;
    margin-bottom: 1em;
}

.aimage-slider-controls-wrapper button:hover {
    cursor: pointer;
}

.aimage-slider-controls-wrapper .aselected-button {
    background-color: #727376;
}

.aimage-slider-controls-wrapper .anot-selected-button {
    background-color: #C4C4C4;
}