.desktop-nav-wrapper {
    text-align: center;
    height: 100%;
    display: none;
    position: absolute;
    right: 0;
    font-size: 16px;
    z-index: 6;
}

.heading-book-now {
    animation: BookNowPulse 3s infinite;
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    border: none;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 15px;
    font-size: 18px;
    font-family: 'Days One', sans-serif;
}

.heading-book-now:hover {
    background: linear-gradient(0deg, #00577d, #00577d);
    cursor: pointer;
}


@keyframes BookNowPulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.desktop-nav-book-now {
    height: calc(100% - 37px);

    padding-top: 28px;
    margin-right: 25px;
}

.desktop-nav-item {
    color: white;
    height: calc(100% - 37px);

    padding-top: 37px;
    margin-right: 25px;
}

.desktop-nav-item:hover {
    cursor: pointer;
    color: #0098da;
    border-top: 3px solid #0098da;
}

.desktop-sub-item a, .desktop-sub-item a:visited {
    color: white;
} 

.free-vr-desktop-sub-item a, .free-vr-desktop-sub-item a  {
    
    color: white;
}

.free-vr-desktop-sub-item {
    position: absolute;
    top: 100px;
    opacity: 0;
    color: white;
    transition: 0.25s;
    border-top: 3px solid #0098da;
    visibility: hidden;
}

.corporate-desktop-sub-item {
    width: 350px;
    position: absolute;
    top: 100px;
    right: 0px;
    opacity: 0;
    color: white;
    transition: 0.25s;
    border-top: 3px solid #0098da;
    visibility: hidden;
}

.my-info-desktop-sub-item {
    width: 350px;
    position: absolute;
    top: 100px;
    opacity: 0;
    color: white;
    transition: 0.25s;
    border-top: 3px solid #0098da;
    visibility: hidden;
}

.waiver-video-desktop-sub-item {
    width: 350px;
    position: absolute;
    top: 100px;
    opacity: 0;
    color: white;
    transition: 0.25s;
    border-top: 3px solid #0098da;
    visibility: hidden;
}

.location-desktop-sub-item {
    width: 350px;
    position: absolute;
    top: 100px;
    opacity: 0;
    color: white;
    transition: 0.25s;
    border-top: 3px solid #0098da;
    visibility: hidden;
}

.free-vr-desktop-item:hover ~ .free-vr-desktop-sub-item {
    opacity: 1;
    visibility: visible;
    transition: 0.75s;
}
.free-vr-desktop-sub-item:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.waiver-video-desktop-item:hover ~ .waiver-video-desktop-sub-item {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.my-info-desktop-item:hover ~ .my-info-desktop-sub-item {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.location-desktop-item:hover ~ .location-desktop-sub-item {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.my-info-desktop-sub-item:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.waiver-video-desktop-sub-item:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.location-desktop-sub-item:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.corporate-desktop-item:hover ~ .corporate-desktop-sub-item {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}
.corporate-desktop-sub-item:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.free-vr-desktop-sub-item ul, .corporate-desktop-sub-item ul, .my-info-desktop-sub-item ul, .waiver-video-desktop-sub-item ul, .location-desktop-sub-item ul {
    background: linear-gradient(90deg, #4B4B4D -3.15%, #242425 99.46%);
    border: 2px solid #000000;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px;
    margin: 0;
    padding: 0;
}

.free-vr-desktop-sub-item li, .corporate-desktop-sub-item li, .my-info-desktop-sub-item li, .waiver-video-desktop-sub-item li, .location-desktop-sub-item li {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 40px;
    padding-left: 40px;
    list-style-type: none;
    text-align: left;
}

.free-vr-desktop-sub-item li:hover, .corporate-desktop-sub-item li:hover, .my-info-desktop-sub-item li:hover, .waiver-video-desktop-sub-item li:hover, .location-desktop-sub-item li:hover {
    background: linear-gradient(90deg, #0098da -3.15%, #242425 99.46%);
    transition: 0.25s;
}

.legal-desktop-sub-menu {
    width: 300px;
    position: absolute;
    left: -298px;
    top: 162px;
    opacity: 0;
    border-top: 3px solid #0098da;
    transition: 0.25s;
    visibility: hidden;
}

.legal-desktop-sub-item:hover ~ .legal-desktop-sub-menu {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.legal-desktop-sub-menu:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.sub-menu-dropdown {
    float: right;
}


@media (min-width: 1500px) {
    .desktop-nav-wrapper {
        display: inline-flex;
    }
}

.dropdown-arrow {
    padding-left: 1em;
    width: 14px;
    height: 14px;
}