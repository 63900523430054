.coverage-preview-wrapper {
    padding: 1em;
    margin: 1em;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid white;
}
.coverage-preview-wrapper:hover {
    cursor: pointer;
}

.coverage-browser-wrapper { 
    width: calc(100% / 3);
    display: inline-flex;
    vertical-align: top;
}