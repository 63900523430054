
.vertical-align-helper {
    width: 100%;
	display: table-cell;
    vertical-align: middle;
}

.event-section {
    width: 100%;
    min-height: 75vh;
	display: table;
}

.event-right {
    width: 30%;
    padding: 5em;
    margin-left: auto;
}

.event-left {
    width: 30%;
    padding: 5em;
    margin-right: auto;
}

.mobile-event {
    padding: 1em;
}

.birthday-padding {
    padding: 3em;
}

.birthday-video{
    width:60vw;
    margin-left: auto;
    margin-right: auto;
}