
.unsupported-browser-modal-wrapper {
    position: fixed; /* Stay in place */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
}

.unsupported-browser-modal-wrapper:hover {
    cursor: pointer;
}

.unsupported-browser-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 1.5em;
    color: black;
    text-shadow: none;
    text-align: center;

    background-color: rgba(255,255,255,0.9);
    margin: auto;
    padding: 20px;
    width: 20%;
    white-space: normal;
    border: 3px solid #0098DA;
    box-sizing: border-box;
    border-radius: 16px;
}

.unsupported-browser-modal:hover {
    cursor: auto;;
}

.close-button:hover{ 
    cursor: pointer;
    background-color: #32b5ee;
    box-shadow: none;
}

.close-button {
    text-align: center;
    appearance: none;
    float: right;
    background-color: #0098da;
    color: white;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border-color: #ccc;
    border-radius: 9px;
    box-shadow: 1px 1px 2px 0px #545454;
}

@media (max-width: 1000px) {
    .unsupported-browser-modal {
        width: 80%;
    }
}
