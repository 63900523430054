
.detected-location-modal-wrapper {
    position: fixed; /* Stay in place */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
}

.detected-location-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: rgba(255,255,255,0.9);
    margin: auto;
    padding: 20px;
    width: 60%;
    white-space: normal;
    border: 3px solid #0098DA;
    box-sizing: border-box;
    border-radius: 16px;
}

.detected-location-modal-section {
    font-size: 24px;
    color: #0098DA;
    text-shadow: none;
    text-align: center;
}

.detected-location-cache-checkbox {
    font-size: 20px;
    color: #0098DA;
    text-align: center;
}

.show-modal {
    opacity: 1;
    visibility: show;
    transition: 1s;
}

.hide-modal {
    opacity: 0;
    visibility: hidden;
    transition: 1s;
}

.treVR-image {
    max-width: 80%;
}

.mobile-treVR-image {
    width: 50%;
}

.detected-popup-or {
    text-align: center;
    color: #0098DA;
    text-shadow: none;
}

.trevr-column {
    width: 30%;
    display: inline-block;  
    vertical-align: middle;
    text-align: center;
}

.options-column {
    width: 70%;
    display: inline-block;  
    vertical-align: middle;
    text-align: left;
}

@media (max-width: 1000px) {
    .detected-location-modal {
        width: 90%;
        max-height: 80%;
        overflow-y: scroll;
    }
}