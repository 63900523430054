.cookie-popup-wrapper {
    position: fixed;
    bottom: 2em;
    z-index: 8;
    width: 100vw;
}

.cookie-popup {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    text-align: center;
    background-color: #1e1e1e;
    border-radius: 10px;
    border: 1px solid #0098DA;
}

.cookie-text {
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 1em;
}

@media (max-width: 930px) {
    .cookie-popup {
        width: 95%;
    }
}