.CheckBox-outer {
    width: 100%;
    margin-top: 5px;
}

.CheckBox-div {
    box-sizing: border-box;
    margin: 0 auto;
    width: 90%;
    text-align: center;
}

.CheckBox-span {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid black;
    box-sizing: border-box;
    display: block;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
    padding-top: 1px;
    cursor: pointer;
    background-color: #fff;
    color: #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
}

.CheckBox-input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.CheckBox-label {
    font-weight: 300 !important;
    margin-bottom: 0px;
}

.CheckBox-label .CheckBox-input:checked ~ .CheckBox-span {
    background-color: #0098DA;
}