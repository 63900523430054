
.faq-modal-wrapper {
    text-shadow: none;
    position: fixed;
    top: 0px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
}

.faq-modal-wrapper:hover {
    cursor: pointer;
}

.faq-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #222222ee;
    margin: auto;
    padding: 20px;
    width: 80%;
    white-space: normal;
    border: 3px solid #0098DA;
    box-sizing: border-box;
    border-radius: 16px;
    
    color: #eee;
}

.faq-modal:hover {
    cursor: default;
}

.show-faq-modal {
    opacity: 1;
    visibility: show;
    transition: 0.5s;
}

.hide-faq-modal {
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
}

.faq-section-heading {
    text-align: center;
    font-size: 20px;
    padding-bottom: 1em;
}

.faq-section-title-wrapper {
    width: calc(100% - 36px);
}

.faq-section-title {
    background-color: #545454;
    color: white;
    -webkit-text-stroke: .5px black;
    font-size: 20px;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    position: relative;
    z-index: 0;
}

.faq-section-title:hover {
    background-color: #0098DA;
    color: white;
    cursor: pointer;
}

.faq-section-body {
    font-size: 14px;
}

.faq-section-body a {
    color: #0098DA;
}

.expand-icon {
    font-size: 28px;
    position: absolute;
    right: 0;
    top: 10%;
    vertical-align: top;
    padding-right: 1em;
}

.close-button:hover{ 
    cursor: pointer;
    background-color: #32b5ee;
    box-shadow: none;
}

.close-button {
    text-align: center;
    appearance: none;
    float: right;
    background-color: #0098da;
    color: white;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border-color:#ccc;
    border-radius: 9px;
    box-shadow: 1px 1px 2px 0px #545454;
}