.seniors-heading {
    font-size: 46px;
    padding-top: 2rem;
}

.seniors-heading-2 {
    font-size: 36px;
    padding-top: 1rem;
}

.seniors-generic-text {
    font-size: 20px;
    padding-top: 0.75rem;
}

.seniors-generic-text-2 {
    font-size: 24px;
    padding-top: 0.75rem;
}

.seniors-fine-print {
    font-size: 14px;
    padding-top: 0.75rem;
}

.seniors-blue-text {
    color: #0098da;
}