.location-dropdown-wrapper {
    background-color: rgba(75, 75, 77, 0.3);
    transition: 0.25s;
    border: 1px solid gray;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    animation: BookNowPulse 3s infinite;
}

.location-dropdown-wrapper:hover {
    background-color: #111111;
    transition: 0.25s;
}

.location-dropdown-heading {
    font-size:34px;
    text-align: center;
    color: #0098da;
    padding: 1em;
}

.location-dropdown-heading-open {
    background-color: #111111;
}

.location-dropdown-heading:hover {
    cursor: pointer;
}

.location-show-dropdown {
    max-height: 1000px;
    overflow-y : hidden;
    transition: 0.5s;
    background-color: #111111;
}

.location-hide-dropdown {
    max-height: 0px;
    overflow-y: hidden;
    transition: 0.5s;
}