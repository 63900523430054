.GOBackdrop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background-color: #33333395;
}
.GOBackdrop1 {
    display:none;
}
.GOPositionWrapper{
    width: 80%;
    height: 100%;
    position: fixed;
    top: 10%;
    left: 6%;
    padding: 1% 25% 1% 25%;
    box-sizing: border-box;
}
.GOText1{
    width: 100%;
    position:relative;
    height: 33%;
    display: block;
    box-sizing: border-box;
    padding: 5% 1% 3% 1%;
    font-size: 28pt;
}
.GOText2{
    width: 100%;
    position:relative;
    height: 33%;
    display: block;
    box-sizing: border-box;
    padding: 3% 1% 3% 1%;
    font-size: 16pt;
}
.blueText{
    color: #0098da;
}
.GOImage{
    width:100%;
    position:relative;
    display:block;
}
.GOMain{
    position: relative;
    display:block;
    background-color: #000000ee;

    text-align: center;

    box-sizing: border-box;
    border: 2px solid #333333;
    border-radius: 5px;

    color: #ffffff;
    border: 2px solid #0098da;

    border-radius: 5px;
}
.GOCloseButton{
    position: absolute;
    top:2px;
    right:2px;
    width:1.1em;
    height: 1.1em;

    text-align: center;

    background-color: #0098da;
    color: #ffffff;

    border: 1px solid #cccccc;
    padding:3px;
    border-radius: 5px;

    box-shadow: 2px 2px 3px 1px #111111;

}
.GOCloseButton:hover{
    background-color: #00abf5;
    box-shadow: 1px 1px 3px 0px #111111;
}

@media(max-width:1000px){
    .GOWrapper{
        padding:0px;
    }
}