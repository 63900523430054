.contest-dropdown-wrapper {
    background-color: rgba(75, 75, 77, 0.3);
    border: 1px solid gray;
}

.contest-dropdown-heading {
    font-size:34px;
    text-align: left;
    color: #0098da;
    padding: 1em;
}

.contest-dropdown-heading:hover {
    cursor: pointer;
}

.show-dropdown {
    max-height: 85vh;
    overflow-y : auto;
    transition: 0.5s;
}

.hide-dropdown {
    max-height: 0px;
    overflow-y: hidden;
    transition: 0.5s;
}