.extra-life-wrapper {
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.t-shirt-iframe {
    width: 100%;
    height: 550px;
    margin-bottom: 2em;
}

.extra-life-sponsor {
    padding-top: 2em;
    padding-bottom: 2em;
    max-width: 80%;
}

.extralife-parallax {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.logo-parallax {
    top: auto !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 40%;
}

.parallax-divider {
    background: linear-gradient(90deg, #3b3b3b, #111111);
}

.extra-logo {
    top: 0 !important;
    left: 0 !important;
    width: 50%;
}

.hour-parallax {
    top: 45% !important;
    left: 0 !important;
    width: 40% !important;
}

.sponsor-hide {
    opacity: 0;
    transition: 0.5s;
}

.sponsor-show {
    opacity: 1;
    transition: 0.5s;
}

.sponsor-container {
    text-align: center;
    vertical-align: middle;
    height: 20%;
}

.sponsor-wrapper {
    height: 100%;
    width: calc(100% / 3);
    vertical-align: middle;
    display: inline-block;
}

.sponsor-padding-wrapper {
    vertical-align: middle;
    height: calc(100% - 2em);
    margin: 1em;
}

.sponsor-image {
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
}

@media (max-width: 1200px) {
    .t-shirt-iframe {
        height: 620px;
    }
}

.extra-life-section-wrapper {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
}

.extra-life-section-text-padding {
    margin-top: 1em;
    margin-bottom: 1em;
}

.extra-life-section-left-right-padding {
    margin-left: 6em;
    margin-right: 6em;
}

.extra-life-larger-font {
    font-size: 90px;
}

.extra-life-mega-font {
    font-size: 110px;
}

.extra-life-ultra-font {
    font-size: 150px;
}

.extra-life-title {
    font-size: 55px;
}

.extra-life-spider-man-font {
    font-family: GoodTimes;
}

.extra-life-furore-font {
    font-family: Furore;
}

.spider-man-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.spider-man-container:hover {
    cursor: pointer;
}

.spider-man-letter-spacing {
    letter-spacing: 0.3em;
}

.spider-man-free-gradiant {
	color: #F3C50E;
}

.spider-man-red {
    color: #D42023;
}

.extra-life-subscript {
    font-size: 16px;
}

.asterisk-super-script {
    position: relative;
    vertical-align: top;
    font-size: 10px;
    top: 2px;
}

.masked-trevr {
    max-width: 50%;
    margin: 1em;
    margin-left: auto;
    margin-right: auto;
}

.small-super-script {
    position: relative;
    vertical-align: top;
    font-size: 40px;
    top: 20px;
}

.extra-small-super-script {
    position: relative;
    vertical-align: top;
    font-size: 20px;
    top: 20px;
}

.extra-life-image {
    
    -webkit-box-shadow: 0 0 3px rgba(0,152,218,0.3);
    box-shadow: 0 0 3px rgba(0,152,218,0.3);

    max-width: 100%;

    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 1em;
}

.extra-life-image-no-overflow {
    max-width: calc(100% - 1em);
    padding: 1em;
}

.add-stroke {
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: white;
}

.add-spider-man-pulse-animation {
    animation: SpidermanPulse 2s infinite;
}

@keyframes SpidermanPulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(218, 0, 0, 0.8);
        box-shadow: 0 0 0 0 rgba(218, 0, 0, 0.8);
    }
    25% {
        -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.glow {
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }
  
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #0098da, 0 0 40px #0098da;
    }
    
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #39c4ff, 0 0 40px #39c4ff, 0 0 50px #39c4ff;
    }
  }