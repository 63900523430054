.escape-title{
    font-size: 4em;
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
}

.top-image-section{
    display: inline-block;
    width: 50%;
    padding-left: 25%;
    padding-right: 25%;
    padding-bottom: 2em;
}

.escape-paragraph-section{
    font-size: 2em;
    text-align: center;
    margin-left: 5em;
    margin-right: 5em;
    padding-bottom: 2em;
}

.escape-paragraph {
    width: 80%;
    padding: 0 10%;
}

.escape-button{
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 1em;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-top: 1em;
    margin-top: 2em;
    font-family: 'Days One', sans-serif;
}

.escape-button:hover {
    background: linear-gradient(0deg, #0098da, #0098da);
    color: white;
    transition: 0.5s;
    
    animation: PulseAndCollapse 3s infinite;
}




.escape-section{
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 2em;
    margin-bottom: 3em;
}

.escape-sub-section{
    display: inline-block;
    width: 50%;
    text-align: center;
    vertical-align:middle;
    padding: 1em 0;
}

.escape-sub-section2{
    width: 80%;
    padding-left: 10%;
}

.escape-sub-section3{
    width: 80%;
    padding-left: 10%;
}

.escape-sub-section4{
    position: relative;
    padding-bottom: 50%;
    padding-top: 30px;
    height: 0;
}

.escape-sub-section-ytl{
    display: inline-block;
    width: 50%;
    text-align: center;
    vertical-align:middle;
    padding: 1em 0;
}

.escape-sub-section-ytl2{
    display: none;
}


.escape-info-section{
    display: inline-block;
    width: 50%;
    text-align: center;
    vertical-align:middle;
}

.escape-heading{
    display: block;
    font-size: 3rem;
    text-align: center;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
    margin-bottom: 1em;
}

.escape-sub-heading-section{
    display: inline-block;
    text-align: left;
    margin-left: 3em;
}

.escape-sub-heading{
    display: inline-block;
    width: 49%;
    font-size: 1.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

.escape-sub-heading2{
    display: inline-block;
    white-space: nowrap;
    width: 49%;
    font-size: 1.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

.escape-sub-heading3{
    display: inline-block;
    white-space: nowrap;
    width: 49%;
    font-size: 1.15em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

.escape-image-section{
    display: inline-block;
    width: 90%;
    vertical-align: middle;
}

.escape-image-section2{
    display: inline-block;
    width: 75%;
    vertical-align: middle;
    padding-left: 4em;
}

.escape-image{
    -moz-box-shadow: 0 0 5px #fff;
    -webkit-box-shadow: 0 0 5px #fff;
    box-shadow: 0px 0px 25px 0.5px #fff;
}

.escape-description{
    width: 85%;
    display: inline-table;
    font-size: 1.35em;
    padding-top: 1.85em;
}

.yt-iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}




@media (max-width:1400px){
    .escape-title{
        font-size: 3em;
        text-align: center;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
    }
    
    .escape-paragraph-section{
        width: 100%;
        font-size: 1.25em;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 2em;
    }

    
    .escape-section{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .escape-sub-section{
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .escape-sub-section2{
        width: 100%;
        padding-left: 0;
    }

    .escape-sub-section3{
        width: 80%;
        padding-left: 0;
    }

    .escape-sub-section-ytl{
        display: none;
    }

    .escape-sub-section-ytl2{
        display: inline-block;
        width: 70%;
        text-align: center;
        vertical-align:middle;
        padding: 1em 0;
    }


    .escape-info-section{
        display: inline-block;
        width: 100%;
        text-align: center;
        vertical-align:middle;
    }

    .escape-heading{
        display: block;
        width: 90%;
        font-size: 2.15rem;
        text-align: center;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
        margin-bottom: 0.5em;
    }
    
    .escape-sub-heading-section{
        display: grid;
        width: 60%;
        text-align: left;
        margin: 0;
        margin-bottom: 2.5em;
        padding-left: 25%;
        padding-right: 15%;
    }

    .escape-sub-heading{
        display:inline-block;
        width: 50%;
        grid-column: 1;
        font-size: 1.15em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        padding-left: 2.25em;
    }

    .escape-sub-heading2{
        display:inline-block;
        width: 50%;
        grid-column: 2;
        font-size: 1.15em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        padding-left: 0.5em;
    }

    .escape-sub-heading3{
        display: inline-block;
        white-space: nowrap;
        width: 49%;
        font-size: .9em;
        padding-top: 0.6em;
        padding-bottom: 0.25em;
        padding-left: 0.5em;
    }

    .escape-image-section{
        display: inline-block;
        width: 75%;
        vertical-align: middle;
    }

    .escape-image-section2{
        display: inline-block;
        width: 75%;
        vertical-align: middle;
        padding-left: 0;
    }

    .escape-description{
        width: 80%;
        display: inline-table;
        font-size: 1em;
        padding-top: 1.85em;
    }
}



@media (max-width:500px){
    .escape-sub-heading-section{
        display: grid;
        width: 100%;
        text-align: left;
        margin: 0;
        margin-bottom: 2.5em;
        padding-left: 0;
        padding-right: 0%;
    }
}