.hs-title{
    font-size: 3.5em;
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
}

.hs-title2{
    font-size: 3em;
    text-align: center;
    padding-bottom: 1em;
    color: #ffffff;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: #0098da;
}
 
.top-image-section{
    display: inline-block;
    width: 40%;
    padding-left: 30%;
    padding-right: 30%;
    padding-bottom: 2em;
}
 
.hs-paragraph-section{
    font-size: 1.5em;
    text-align: center;
    margin-left: 3em;
    margin-right: 3em;
    padding-bottom: 2em;
}
 
.hs-paragraph {
    width: 90%;
    padding: 0 5%;
}
 
.hs-button{
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 1em;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-top: 1em;
    margin-top: 2em;
    font-family: 'Days One', sans-serif;
}
 
.hs-button:hover {
    background: linear-gradient(0deg, #0098da, #0098da);
    color: white;
    transition: 0.5s;
   
    animation: PulseAndCollapse 3s infinite;
}
 
 
 
 
.hs-section{
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 2em;
    margin-bottom: 3em;
}

.hs-section2{
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 2em;
    margin-bottom: 3em;
}
 
.hs-sub-section{
    display: inline-block;
    width: 50%;
    color: #FFF;
    -webkit-text-stroke-width: 0.05em;
    -webkit-text-stroke-color: #0098da;
    text-shadow: 0.1em 0.1em 0.1em #000;
    text-align: center;
    vertical-align:middle;
    padding: 1em 0;
}
 
.hs-sub-section2{
    width: 80%;
    padding-left: 10%;
}
 
.hs-sub-section3{
    width: 80%;
    padding-left: 10%;
}
 
.hs-sub-section4{
    position: relative;
    padding-bottom: 50%;
    padding-top: 30px;
    height: 0;
}

.hs-sub-section5{
    display: inline-block;
    width: 25%;
    vertical-align:middle;
}
 
.hs-sub-section-ytl{
    display: inline-block;
    width: 50%;
    text-align: center;
    vertical-align:middle;
    padding: 1em 0;
}
 
.hs-sub-section-ytl2{
    display: none;
}
 
 
.hs-info-section{
    display: inline-block;
    width: 50%;
    color: white;
    -webkit-text-stroke-width: 0.07em;
    -webkit-text-stroke-color: #0098da;
    text-shadow: 0.1em 0.1em 0.1em #000;
    text-align: center;
    vertical-align:middle;
}
 
.hs-heading{
    display: block;
    font-size: 3rem;
    text-align: center;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
    margin-bottom: 1em;
}

.hs-heading2{
    display: block;
    font-size: 3rem;
    text-align: center;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
    margin-bottom: 0.25em;
}

.hs-heading3{
    display: block;
    width: 100%;
    font-size: 3rem;
    text-align: center;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
    margin-bottom: 0.25em;
}
 
.hs-sub-heading-section{
    display: inline-block;
    text-align: left;
    margin-left: 3em;
}
 
.hs-sub-heading{
    display: inline-block;
    width: 49%;
    font-size: 2.25em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}
 
.hs-sub-heading2{
    display: inline-block;
    white-space: nowrap;
    width: 49%;
    font-size: 2.25em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}
 
.hs-sub-heading3{
    display: inline-block;
    width: 49%;
    font-size: 2em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    margin-top: 1em;
}

.hs-sub-heading4{
    display: inline-block;
    width: 95%;
    font-size: 1.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    margin-top: 0.5em;
}

.hs-sub-heading5{
    display: inline-block;
    width: 100%;
    font-size: 2em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    margin-top: 0.5em;
}
 
.hs-image-section{
    display: inline-block;
    width: 90%;
    vertical-align: middle;
}
 
.hs-image-section2{
    display: inline-block;
    width: 75%;
    vertical-align: middle;
    padding-left: 4em;
}
 
.hs-image{
    -moz-box-shadow: 0 0 5px #0098da;
    -webkit-box-shadow: 0 0 5px #0098da;
    box-shadow: 0px 0px 25px 0.5px #0098da;
}
 
.hs-description{
    width: 85%;
    display: inline-table;
    font-size: 1.35em;
    padding-top: 1.85em;
}

.hs-description2{
    width: 85%;
    display: inline-table;
    font-size: 1.35em;
}

.hs-description3{
    width: 85%;
    font-size: 1.35em;
    text-align: left;
    margin-top: 1em;
}
 
.yt-iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hs-blue{
    display: inline;
    color: #0098DA;
    margin-block-start: 0;
    margin-block-end: 0;   
}

.hs-margin{
    margin-top: 3em;
}

.hs-pulse-animation {
    animation: HSPulse 3s infinite;
}

@keyframes HSPulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.hs-button {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 1.75em;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin: 2em 0;
    font-family: 'Days One', sans-serif;
}

.hs-button:hover { 
    background: linear-gradient(0deg, #00577d, #00577d);
    cursor: pointer;
}

.hs-button2 {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 1em;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin: 1em 0;
    font-family: 'Days One', sans-serif;
}

.hs-button2:hover { 
    background: linear-gradient(0deg, #00577d, #00577d);
    cursor: pointer;
}
 
 
 
 
@media (max-width:1400px){
    .hs-title{
        font-size: 2.5em;
        text-align: center;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
    }

    .hs-title2{
        font-size: 2em;
        text-align: center;
        padding-bottom: 1em;
        color: #ffffff;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: #0098da;
    }
    
   
    .hs-paragraph-section{
        width: 100%;
        font-size: 1.25em;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 2em;
    }
 
   
    .hs-section{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .hs-section2{
        display: block;
        width: 100%;
        height: 100%;
        margin-top: 2em;
        margin-bottom: 3em;
    }
 
    .hs-sub-section{
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
 
    .hs-sub-section2{
        width: 100%;
        padding-left: 0;
    }
 
    .hs-sub-section3{
        width: 50%;
        padding-left: 0;
    }

    .hs-sub-section4{
        position: relative;
        padding-bottom: 50%;
        padding-top: 30px;
        height: 0;
    }
    
    .hs-sub-section5{
        display: inline-block;
        width: 25%;
        vertical-align:middle;
    }    
 
    .hs-sub-section-ytl{
        display: none;
    }
 
    .hs-sub-section-ytl2{
        display: inline-block;
        width: 50%;
        text-align: center;
        vertical-align:middle;
        padding: 1em 0;
    }
 
 
    .hs-info-section{
        display: inline-block;
        width: 50%;
        text-align: center;
        vertical-align:middle;
    }
 
    .hs-heading{
        display: block;
        width: 90%;
        font-size: 2.15rem;
        text-align: center;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
        margin-bottom: 0.5em;
    }

    .hs-heading2{
        display: block;
        width: 90%;
        font-size: 2.15rem;
        text-align: center;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
        margin-bottom: 0.25em;
    }
     
    .hs-heading3{
        display: block;
        width: 90%;
        font-size: 2.15rem;
        text-align: center;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
        margin-bottom: 0.25em;
    }
   
    .hs-sub-heading-section{
        display: grid;
        width: 60%;
        text-align: left;
        margin: 0;
        margin-bottom: 2.5em;
        padding-left: 25%;
        padding-right: 15%;
    }
 
    .hs-sub-heading{
        display:inline-block;
        width: 50%;
        grid-column: 1;
        font-size: 1.75em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        padding-left: 0.5em;
    }
 
    .hs-sub-heading2{
        display:inline-block;
        width: 50%;
        grid-column: 2;
        font-size: 1.75em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        padding-left: 0.5em;
    }
 
    .hs-sub-heading3{
        display: inline-block;
        white-space: nowrap;
        width: 49%;
        font-size: 1.75em;
        padding-top: 0.6em;
        padding-bottom: 0.25em;
    }

    .hs-sub-heading4{
        display: inline-block;
        width: 75%;
        font-size: 1.25em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        margin-top: 0.5em;
    }

    .hs-sub-heading5{
        display: inline-block;
        width: 100%;
        font-size: 1.75em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        margin-top: 0.5em;
    }
 
    .hs-image-section{
        display: inline-block;
        width: 75%;
        vertical-align: middle;
    }
 
    .hs-image-section2{
        display: inline-block;
        width: 75%;
        vertical-align: middle;
        padding-left: 0;
    }
 
    .hs-description{
        width: 80%;
        display: inline-table;
        font-size: 1.25em;
        padding-top: 1.85em;
    }

    .hs-description2{
        width: 80%;
        display: inline-table;
        font-size: 1.25em;
    }
     
    .hs-description3{
        width: 80%;
        font-size: 1.10em;
        text-align: left;
    }
    
    .hs-margin{
        margin-top: 2em;
    }
    
    .hs-button {
        background: linear-gradient(0deg, #00577d, #0098DA);
        color: white;
        font-size: 1.5em;
        text-align: center;
        border-radius: 8px;
        border: none;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin: 2em 0;
        font-family: 'Days One', sans-serif;
    }

    .hs-button2 {
        background: linear-gradient(0deg, #00577d, #0098DA);
        color: white;
        font-size: 0.90em;
        text-align: center;
        border-radius: 8px;
        border: none;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin: 1em 0;
        font-family: 'Days One', sans-serif;
    }
}
 
 
 
@media (max-width:500px){
    .hs-title{
        font-size: 2em;
        text-align: center;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
    }

    .hs-title2{
        font-size: 1.75em;
        text-align: center;
        padding-bottom: 1em;
        color: #ffffff;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: #0098da;
    }

    .hs-paragraph-section{
        width: 100%;
        font-size: 1em;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 2em;
    }
    
    .hs-sub-heading-section{
        display: grid;
        width: 100%;
        text-align: left;
        margin: 0;
        margin-bottom: 2.5em;
        padding-left: 0;
        padding-right: 0%;
    }

    .hs-info-section{
        display: inline-block;
        width: 60%;
        text-align: center;
        vertical-align:middle;
    }

    .hs-section{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5em;
    }

    .hs-section2{
        display: block;
        width: 100%;
        height: 100%;
        margin-top: 0;
        margin-bottom: 3em;
    }

    .hs-description{
        width: 80%;
        display: inline-table;
        font-size: 1em;
        padding-top: 1.85em;
    }

    .hs-description2{
        width: 80%;
        display: inline-table;
        font-size: 1em;
    }
     
    .hs-description3{
        width: 80%;
        font-size: 1em;
        text-align: left;
    }

    .hs-sub-heading3{
        display: block;
        width: 60%;
        font-size: 1.75em;
        padding-top: 0.6em;
        padding-bottom: 0.25em;
        padding-left: 0;
        white-space: pre-line;
    }

    .hs-sub-heading4{
        display: inline-table;
        width: 90%;
        font-size: 1.25em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        margin-top: 0.5em;
    }

    .hs-sub-heading5{
        display: inline-block;
        width: 100%;
        font-size: 1.25em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        margin-top: 0.5em;
    }

    .hs-sub-section5{
        display: inline-block;
        width: 50%;
        vertical-align:middle;
    } 

    .hs-sub-section-ytl2{
        display: inline-block;
        width: 60%;
        text-align: center;
        vertical-align:middle;
        padding: 1em 0;
    }

    .top-image-section{
        display: inline-block;
        width: 55%;
        padding-left: 20%;
        padding-right: 30%;
        padding-bottom: 2em;
    }

    .hs-image-section{
        display: inline-block;
        width: 85%;
        vertical-align: middle;
    }
 
    .hs-image-section2{
        display: inline-block;
        width: 85%;
        vertical-align: middle;
        padding-left: 0;
    }

    .hs-button {
        background: linear-gradient(0deg, #00577d, #0098DA);
        color: white;
        font-size: 1.25em;
        text-align: center;
        border-radius: 8px;
        border: none;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin: 2em 0;
        font-family: 'Days One', sans-serif;
    }

    .hs-button2 {
        background: linear-gradient(0deg, #00577d, #0098DA);
        color: white;
        font-size: 0.75em;
        text-align: center;
        border-radius: 8px;
        border: none;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin: 1em 0;
        font-family: 'Days One', sans-serif;
    }
}