.location-pin-wrapper {
    position: relative;
}

.location-pin-modal {
    position: absolute;
    top: -64px;
    transform: translate(-50%, -100%);
    background-color: black;
    padding: 1em;
    white-space: nowrap;
    font-size: 18px;
}

.show-pin-modal {
    opacity: 1;
    visibility: show;
    transition: 1s;
}

.hide-pin-modal {
    opacity: 0;
    visibility: hidden;
    transition: 1s;
}

.location-pin {
    transform: translate(-50%, -100%);
}