.popupBackdrop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;

    background-color: #222222aa;
}
.popupPositionWrapper{
    width: 100%; 
    position: fixed;
    height: 100%;
    box-sizing: border-box;
}
.popupWrapper{
    padding:20px;
    display:block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    z-index: 300;
    box-sizing: border-box;
}
.popupMain{
    position: relative;
    display:block;
    background-color: #444444ee;
    padding: 30px;

    text-align: center;

    box-sizing: border-box;
    border: 2px solid #333333;
    border-radius: 5px;

    color: #ffffff;
    border: 2px solid #0098da;

    border-radius: 5px;
}
.popupCloseButton{
    position: absolute;
    top:2px;
    right:2px;
    width:1.1em;
    height: 1.1em;

    background-color: #0098da;
    color: #ffffff;

    border: 1px solid #cccccc;
    padding:3px;
    border-radius: 5px;

    box-shadow: 2px 2px 3px 1px #111111;

}
.popupCloseButton:hover{
    background-color: #00abf5;
    box-shadow: 1px 1px 3px 0px #111111;
}

@media(max-width:1000px){
    .popupWrapper{
        padding:0px;
    }
}