
.games-wrapper {
    padding-left: 4em;
    padding-right: 4em;
}

.mobile-games-wrapper {
    text-align: center;
}

.games-section {
    padding: 3em;
}

.mobile-game-section {
    padding-top: 1em;
    padding-bottom: 1em;
}


.game-title {
    font-size: 34px;
    padding-bottom: 1em;
}

.left-game-title {
    text-align: left;
}

.center-game-title {
    text-align: center;
}

.right-game-title {
    text-align: right;
}

.game-logo {
    text-align: center;
    display: inline-block;
    width: 30%;
    vertical-align: middle;
}

.game-logo-img {
    max-width: 100%;
    max-height: 100%;
}

.game-body-gallery {
    text-align: center;
}

.game-body-gallery img {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    width: 320px;
    height: 202px;
    margin: 1em;

    -moz-box-shadow: 0 0 5px #fff;
    -webkit-box-shadow: 0 0 5px #fff;
    box-shadow: 0px 0px 5px #fff;
}

.mobile-gallery img {
    padding-bottom: 1em;
}

.game-description {
    display: inline-block;
    width: 70%;
    vertical-align: middle;
}

.game-description-padding {
    padding: 2em;
}

.mobile-game-description {
    padding-top: 1em;
}