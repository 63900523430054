
.pressroom-video {
    margin: 1em;
    
    -webkit-box-shadow: 0 0 5px rgba(0,152,218,0.3);
    box-shadow: 0 0 3px rgba(0,152,218,0.3);

}

.desktop-pressroom-video {
    display: inline-block;
    width: calc(100% / 3);
}

.pressroom-post img {
    max-width: 100%;
    height: auto;
}

.parallax-pressroom-header {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/3297ac15-press_room.png");
    height: 60vh;
}
