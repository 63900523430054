
.rel {
    position: relative;
}

.trevr-robit-parallax {
    width: 35%;
    position: absolute;
    right: auto !important;
    left: 0 !important;
    top: auto !important;
    bottom: 0 !important;
}

.green-robit-parallax {
    position: absolute;
    right: 0 !important;
    left: auto !important;
    top: auto !important;
    bottom: 0 !important;
    width: 45%;
}

.text-overlay-parallax {
    height: 100%;
}
