.video-image-wrapper {
    position: relative;
}

.video-image-wrapper:hover {
    cursor: pointer;
}

.play-button {
    position: absolute;

    top: calc(50% - 46.5px);
    left: calc(50% - 41px);
}

.video-banner {
    width: 100%;
}

.youtube-iframe-wrapper {
    width: 100%;
    position: relative;
    padding-top:56.25%
}

.youtube-iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}