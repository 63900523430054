.locations-wrapper {
    padding: 1em;
    font-size: 18px;
    text-align: center;
}

.locations-heading, .country-list-title {
    font-size: 2.75em;
    padding-top: 1em;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
}

.locations-sub-heading, .province-list-title {
    font-size: 2em;
    margin-top: 0.75em;
    text-decoration: underline;
}

/* Province Grid Centering Logic */
.country-list {
  justify-content: space-evenly;
}

.country-list-content {
    display: grid;
    grid-template-columns:repeat(auto-fit, minmax(max(425px,100%/3),1fr));
}

.province-list-wrapper {
    width: 425px;
    margin: auto;
}

.province-list {
    width: 100%;
}
/* End of Grid Logic */

.locations-map-wrapper {
    vertical-align: top;
    display: inline-block;
    width: 50%;
    height: calc(100vh - 176px);
}

.locations-list-wrapper {   
    vertical-align: top;
    display: inline-block;
    width: 70%;
}

.location-list-item {
    font-size:1.7em;
    color: #0098da;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
}

.location-list-button {
    background: linear-gradient(0deg, #000000, #000000);
    transition: 0.5s;
    box-shadow:10px 10px 0 rgba(0,0,0,.5);
    display:inline-block;
    padding:.2em 1em;
    text-decoration:none;
    box-sizing: border-box;
}

.location-list-button:hover {
    background: linear-gradient(0deg, #0098da, #0098da);
    color: white;
    transition: 0.5s;
    
    animation: PulseAndCollapse 3s infinite;
}

.location-list-revshare{
    background: linear-gradient(0deg, #003b88, #005eda) !important;
}

.location-list-revshare:hover{
    background: linear-gradient(0deg, #003b88, #003b88) !important;
}

.desktop-list-size {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.mobile-list-size {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@keyframes PulseAndCollapse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
}

.parallelogram {
  transform: skew(-20deg);
  border: 1em;
  border-width: 0.1em;
  border-style: outset;
  border-color: white;
  border-radius: 2rem;
}

.skew-fix {
    transform: skew(20deg);
    display:inline-block;
    color: #0098da;
}

.bottom-spacing {
    margin-bottom: 4em;
}


@media (max-width: 900px) {
    .location-list-item {
        font-size:1.5em;
        color: #0098da;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .locations-list-wrapper {   
        vertical-align: top;
        display: inline-block;
        width: 80%;
    }
}