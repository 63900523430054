.faq-list li {
    list-style-type: decimal;
    list-style-position:inside;
}

.faq-wrapper {
    padding: 1em;
}

.faq-browser-wrapper {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.faq-heading {
    font-size: 20px;
    padding-top: 1em;
    padding-bottom: 1em;
}