.heading {
    font-size: 60px;
    padding-top: 2rem;
    text-decoration: underline;
}

.heading-2 {
    font-size: 36px;
    padding-top: 1rem;
}

.province-heading {
    font-size: 44px;
    padding-top: 1rem;
    color: #0098da;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.location-heading {
    font-size: 32px;
    padding-top: 1rem;
}

.location-text {
    font-size: 24px;
    padding-top: 0.25rem;
}

.generic-text {
    font-size: 24px;
    padding-top: 0.75rem;
}

.generic-text-2 {
    font-size: 24px;
    padding-top: 0.75rem;
}

.generic-text-3 {
    font-size: 20px;
    padding-top: 0.75rem;
}

.fine-print {
    font-size: 14px;
    padding-top: 0.75rem;
}

.bottom-padding {
    padding-bottom: 0.75em;
}

.top-padding {
    padding-top: 0.75em;
}

.top-padding-2 {
    padding-top: 2em;
}

.blue {
    color: #0098da;
    font-size: 46px;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    font-weight: bold;
}