
.main-header {
    position: sticky;
    width: 100%;
    height: 100px;
    left: 0px;
    top: 0px;
    
    background: linear-gradient(180deg,hsla(75,0%,100%,.3),hsla(0,0%,100%,0)),rgba(25, 25, 25, 0.9);
    z-index: 9;
}

.logo {
    padding-left: 20px;
    padding-top: 3px;

    width: auto;
    height: 70px;
}

@media (max-width: 700px) {
    .main-header {
        height: 60px;
    }
    .logo {
        height: 45px;
        width: auto;
    }
}