.home-wrapper {
    text-align: center;
}

.mobile-manta-ray-container {
    position: relative;
    text-align: center;
    overflow-x: hidden;
}

.mobile-manta-ray-container .parallax-js-image-center {
    max-width: 100%;
}

.manta-ray-image {
    min-width: 100vw;
    min-height: 100vh;
}

.manta-ray-heading {
    position: relative;
    width: 98%;
    padding-top: 0;
}

.desktop-banner {
    color: #fff;
    text-shadow: #000 0px 0px 8px;
    text-shadow: 3px 3px black;
    font-size: 60px;
}

.mobile-banner {
    font-size: 25px;
    color: #fff;
    text-shadow: #000 0px 0px 8px;
    text-shadow: 3px 3px black;
}

.black-box {
    background-color: rgba(0,0,0,.5);
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 25px;
}

.manta-ray-heading-2 {
    position: absolute;
    top: 65%;
    width: 100%;
}

.parent-request {
    font-size: 18px;
}

.desktop-manta-ray-container {
    position: relative;
    top: -50px;
    margin-bottom: -50px;
    height: 90vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.game-scroller-wrapper {
    width: 25%;
    margin: auto;
}


.bottom-padding {
    padding-bottom: 1em;
}

.homepage-review-wrapper {
    padding-bottom: 1em;
}

.padded{
    padding:10px;
    color:white
}

.spanning{
    width:100%;
    text-align: center;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
}
.warning-gradient{
    background: linear-gradient(to bottom, #FFEB3B , #FFC107);
    padding: 15px;

    cursor: pointer;
    color:black;
    text-shadow: none;
}
.warning-gradient:hover{
    background: none;
    background-color: rgb(223, 167, 0);
}

.stick-to-bottom{
    position:fixed;
    bottom:0;
    z-index: 2001;
}