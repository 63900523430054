
.show404-error-code {
    font-size: 128px;
    text-align: center;
}

.show404-sub-heading {
    font-size: 64px;
    text-align: center;
}
