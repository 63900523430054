@import url('https://fonts.googleapis.com/css?family=Days+One');

/* outer div */
.FacilityHours {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  /* desktop: 16px, mobile: 13px */
  font-size: 16px;
  font-family: "Days One", "Trebuchet MS", Helvetica, sans-serif;
  background: rgba(0, 0, 0, 0);
  color: #edeaea;

  /* simulate iframe box for manual centering desktop: 189px, mobile: 117px
  margin: auto;
  box-sizing: content-box;
  height: 189px;
  width: 500px; */
}

.facility-hour-padding {
  margin-bottom: 5px;
  margin-top: 5px;
}



