
.searchIcon {
    width: 40%;
    padding-top: 32px;
}

.searchIcon:hover {
    cursor: pointer;
}
.searchText:hover{
    cursor: pointer;
}

.search-control-wrapper {
    position: absolute;
    top: 100px;
    right: 0px;
    text-align: right;
    z-index: 500;
    background: linear-gradient(90deg, #4B4B4D -3.15%, #242425 99.46%);
    border-top: 3px solid #0098da;
    width: 350px;
}

.inline-search-wrapper{
    width:100%;
}

.search-controls {
    padding: 1em;
}

.search-textbox {
    background: #4a4a4a80;
    width: 100%;
    height: 100%;
    padding: 0;

    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    height: 34px;
    color: white;

    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.search-button {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;

    font-family: 'Days One', sans-serif;
}

.inline-search-textbox {
    background: #4a4a4a80;
    color:white;

    width: 60%;

    border: 2px solid #ffffff;
    border-radius: 4px;

    padding-top:0.5em;
    padding-bottom:0.4em;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}
.inline-search-button {
    background: linear-gradient(0deg, #00435f, rgb(0, 168, 240));
    color: white;
    font-size: 15px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.5em;
    padding-left: 0.5em;

    margin-left: 1em;

    font-family: 'Days One', sans-serif;
}

.search-button:hover { 
    background: linear-gradient(0deg, #00577d, #00577d);
    cursor: pointer;
}

.search-result {
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 40px;
    padding-left: 40px;
    list-style-type: none;
    text-align: left;
}

.search-result:hover {
    background: linear-gradient(90deg, #0098da -3.15%, #242425 99.46%);
}
.search-result-list{
    max-height: 12em;
    overflow-y: auto;
}
.search-result-list-inline{
    max-height: 8em;
    overflow-y: scroll;
    margin-right: 3px;

    width:calc(100% - 30px);

    /*
    */
    scrollbar-width: thin !important;
    scrollbar-color: #242425 #eeeeee77 ;
}

.search-result-list-inline::-webkit-scrollbar{
    width: 5px !important;
}
.search-result-list-inline::-webkit-scrollbar-track{
    background-color: #eeeeee77;

    border-radius: 5px;
}
.search-result-list-inline::-webkit-scrollbar-thumb{
    background-color: #242425;
    border-radius: 36px;
}


.search-result-list-inline .search-result{
   padding-left: 0px; 
   padding-right: 0px;


   border-bottom: 1px solid #002231;
}