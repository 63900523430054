.generic-wrapper {  
    padding: 1em;
    font-size: 18px;
    text-align: center;
    overflow-wrap: break-word;
}

.generic-wrapper-no-padding {
    font-size: 18px;
    text-align: center;
    overflow-wrap: break-word;
}

.mobile-wrapper {
    padding: 1em;
}

.browser-wrapper {
    padding: 1em;
    font-size: 18px;
    text-align: center;
    overflow-wrap: break-word;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.browser-section {
    padding: 2em;
}

.face-section {
    padding-left: 1em;
    padding-right: 1em;
}

.mobile-section {
    padding: 2em;
}

.browser-column {
    width: 50%;
    display: inline-block;  
    vertical-align: middle;
    text-align: left;
}

.browser-column-30 {
    width: calc(100% / 3);
    display: inline-block;  
    vertical-align: middle;
}

.browser-column-align-top {
    vertical-align: top;
}

.browser-text-padding {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 2em;
    padding-right: 2em;
    text-align: justify;
}

.center-image {
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.generic-wrapper li, .generic-wrapper-no-padding li {
    text-align: left;
}

.generic-heading {
    font-size: 34px;
}

.game-section-padding {
    padding-top: 1em;
}

.generic-heading-padding {
    padding-top: 1em;
    padding-bottom: 1em;
}

.top-padding {
    padding-top: 1em;
}

.blue-sub-headings .generic-sub-heading {
    color: #0098DA;
}

.generic-sub-heading {
    font-size: 24px;
}

.generic-image {
    -webkit-box-shadow: 0 0 3px rgba(0,152,218,0.3);
    box-shadow: 0 0 3px rgba(0,152,218,0.3);
    width: 100%;
}

.parallax-js-image {
    width: 100%;
}

.browser-image {
    width: 50%;
}

.generic-section {
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: justify;

}

.generic-footnote {
    font-size: 16px;
    text-align: center;
    color: #0098da;
    
}

.generic-footnote-white {
    font-size: 14px;
    text-align: center;
    
}

.trevr-img {
    margin-bottom: 2em;
}

.generic-pulse-animation {
    animation: BookNowPulse 3s infinite;
}

@keyframes BookNowPulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.generic-location-button {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin: 1em;
    font-family: 'Days One', sans-serif;
}

.generic-rd-button {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.0em;
    padding-left: 1.0em;
    margin: 1em;
    font-family: 'Days One', sans-serif;
}

.generic-location-button:hover { 
    background: linear-gradient(0deg, #00577d, #00577d);
    cursor: pointer;
}

.numbered-list {
    list-style-type: decimal;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg,rgba(0,152,218,0),rgba(0,152,218,.5));
    opacity: 0;
    transition: 1s;
}

.image-overlay:hover {
    background-image: linear-gradient(180deg,rgba(0,152,218,0),rgba(0,152,218,.5));
    opacity: 1;
    transition: 1s;
}

.parallax-js-image-center {
    left: 25% !important;
    top: auto !important;
    bottom: 0 !important;
}

.parallax-js-image-background {
    min-width: 100%;
    height: 100% !important;
}

@media (max-width: 600px) {
    .parallax-js-image-center {
        left: 0 !important;
        top: auto !important;
        bottom: 0 !important;
    }

    .parallax-js-image-background {
        height: 300px !important;
        max-width: 100%;
        max-height: 50vh !important;
    }
}

.hide-parallax {
    opacity: 0 !important;
    transition: opacity 1s, left 3s;

    left: -30% !important;
    top: auto !important;
    bottom: 0 !important;
    max-width: 100%;
}

.smol-girl {
    height: 65%;
}

.smol-boy {
    height: 65%;
}

.big-boy {
    height: 65%;
}

.not-smol-girl {
    height: 65%;
}

@media (max-width: 600px) {

    .smol-girl {
        height: 85%;
    }
    
    .smol-boy {
        height: 85%;
    }
    
    .big-boy {
        height: 85%;
    }
    
    .not-smol-girl {
        height: 85%;
    }
}

sup {
    vertical-align: text-top;
    position: relative;
}