.superscript-font {
    font-size: 36px;
}

.annivrsary-heading {
    font-size: 42px;
}

.annivrsary-title {
    font-size: 86px;
    margin: 0.1em 0;
}

.annivrsary-stroke {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: white;
}

.box-shadow-image {
    -webkit-box-shadow: -2px 2px 8px white;
    box-shadow: -2px 2px 8px white;
    width: 100%;
    margin: 0.5em 0;
}

.margin {
    margin: 2em 0;
}

.small-margin {
    margin: 1em 0;
}