.side-tray-wrapper {
    position: fixed;
    top: 100px;
    width: 90%;

    background: linear-gradient(90deg, #4B4B4D -3.15%, #242425 99.46%);
    border: 2px solid #000000;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px;

    z-index: 9;

    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 100px);
}

.side-tray-wrapper a, .side-tray-wrapper a:visited {
    color: white;
}

.side-tray-wrapper .sub-menu li {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 35px;
}

.side-tray-wrapper .sub-sub-menu li {
    padding-left: 45px;
}

.side-tray-wrapper ul {
    padding: 0;
    margin: 0;
}

.side-tray-wrapper li {
    font-size: 20px;
    line-height: 26px;

    width: 100%;
    border: 1px solid black;

    list-style-type: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
}

.open-side-tray {
    margin-left: 10%;
    transition: margin 0.3s;
}

.closed-side-tray {
    margin-left: 190%;
    transition: margin 0.3s;
}

.freevr-side-tray-sub-item {
    height: 152px;
    transition: 0.3s;
}

.corperate-hide-franchsing {
    height: 190px;
    transition: 0.3s;
}

.corperate-side-tray-sub-item {
    height: 152px;
    transition: 0.3s;
}

.legal-side-tray-sub-item {
    height: 152px;
    transition: 0.3s;
}

.my-info-side-tray-sub-item {
    height: 76px;
    transition: 0.3s;
}

.waiver-video-side-tray-sub-item {
    height: 76px;
    transition: 0.3s;
}

/* height is a multiple of 38px x number of elements in <ul> */
.locations-side-tray-sub-item {
    height: 342px;
    transition: 0.3s;
}

.closed-side-tray-sub-item {
    height: 0px;
    overflow: hidden;
    transition: 0.3s;
}

.side-tray-sub-menu-dropdown {
    position:absolute;
    right: 15%;
    top: calc(50% - 7px);
    width: 14px;
    height: 14px;
}

.sub-menu-li {
    position: relative;
}


.search{
    background: linear-gradient(to bottom, #0098da -3.15%, #005c83 99.46%);
}
.search-dropdown-arrow{
    position: absolute;
    height: 70px;
    width:100%;

    pointer-events: none;

    box-sizing: border-box;
}

@media (min-width: 1500) {
    .side-tray-wrapper {
        display: none;
    }
}

@media (max-width: 700px) {
    .side-tray-wrapper {
        top: 60px;
    }
    
}