.modal {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Overlay effect: translucent background: black w/ partial opacity */
    z-index: 1000; /* Overlay effect: positioned over other containers */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: fixed; /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    padding-top: 80px; /* Location of the content container */
}
/* Modal content */
.modal-content {
    position: relative;
    background-color: white;
    width: 40%;
    min-width: 400px; /* Width in proportion to its parent container*/
    max-width: 640px; /* Max width where it stops expanding */
    margin: auto;
    padding: auto; /* Auto margin according to the element width */
    margin-top: 40px;
    border-radius: 10px;
    border-color: #333333;
    color: black;
    text-shadow: "none";
    padding-bottom: 30px;
    overflow-y: auto;/* Optional. Rounds container corners */
}
/* Close button */

.center {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
  }

.modal-header {
    padding: 10px;
    background-color: #333333;
    border-color: #333333;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: white;
    text-align: "center";
}

.close {
   margin-top: -1%;
   color: white;
   float: right; /* Positioned to the right of the parent container whichever size it is */
   font-size: 25px;
   font-weight: bold;
}
.close:hover, 
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
