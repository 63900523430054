.book-now-popup {
    display: none;
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;

    bottom: 6px;
    left: 0;
    position:fixed;

    padding: .5em;
    padding-left: 1em;
    padding-right: 1em;
    margin: 1em;
    border-radius: 15px;
    z-index: 7;
    font-size: 18px;
    font-family: 'Days One', sans-serif;
    animation: BookNowPulse 3s infinite;
}

.book-now-popup:hover {
    cursor: pointer;
    transition: 0.5s;
    background: linear-gradient(0deg, #00577d, #00577d);
}

.book-now-popup a {
    color:white;
}

@media (max-width: 1500px){
    .book-now-popup{
        display: inline;
    }
}

@-webkit-keyframes BookNowPulse {

    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

@keyframes BookNowPulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}