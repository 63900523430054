

.featured-game-padding {
    margin-left: 1em;
    margin-right: 1em;
    position: relative;
}

.featured-game {
    display: inline-block;
    width: calc(100% / 5);
}

.featured-game-image:hover {
    cursor: pointer;
}