.lightbox-image-container {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.lightbox-image-wrapper {
    background-color: rgba(63, 63, 63, 0.6);
    text-align: center;
    width: 100%;
    height: 100%;
}

.lightbox-image {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50vh;
    transform: translateY(-50%);

    width: 720px;
    height: auto;
}