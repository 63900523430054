.image-slider-wrapper {
    position: relative;
    width:100%;
    height:calc(100vw/2.38);
    overflow: hidden;
}

.desktop-image-slider-wrapper {
    background: linear-gradient(to bottom, #111, #000);
    position: relative;
    width: 100%;
    height: 100%;
}

.desktop-image-slider-wrapper .image-slider-image {
    text-align: center;
    background: linear-gradient(to bottom, #111, #000);
    transform: translateX(-50%);
    left:50%;
    position: absolute;
    top: 0px;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.desktop-image-slider-wrapper .image-slider-image-2 {
    text-align: center;
    left:50%;
    transform: translateX(-50%);
    background: linear-gradient(to bottom, #111, #000);
    position: absolute;
    top: 0px;
    object-fit: contain;
    width: 100%;
    height: 100%;
}
@media(max-width:600px){
    .desktop-image-slider-wrapper .image-slider-image {
        object-fit: fill;
        background-color: #111;
    }
    .desktop-image-slider-wrapper .image-slider-image-2 {
        object-fit: fill;
        background-color: #111;
    }
}
.image-transition-show {
    opacity: 1;
    transition: 1s;
}

.image-transition-hide {
    opacity: 0;
    visibility: hidden;
}

.mobile-image-slider-wrapper {
    position: relative;
    overflow: hidden;
}

.mobile-image-slider-wrapper .image-slider-image {
    margin-left: 50%;
    transform: translateX(-50%);
    object-fit: fill;
}

.mobile-image-slider-wrapper .image-slider-image-2 {
    margin-left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 0px;
    object-fit: fill;
}

.image-slider-controls-wrapper {
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%);
}

.image-slider-controls-wrapper button {
    display: inline-block;

    text-align: center;
    border-radius: 8px;
    border: none;
    padding: 0.5em;
    margin: 0.25em;
    margin-bottom: 1em;
}

.image-slider-controls-wrapper button:hover {
    cursor: pointer;
}

.image-slider-controls-wrapper .selected-button {
    background-color: #727376;
}

.image-slider-controls-wrapper .not-selected-button {
    background-color: #C4C4C4;
}