.loyalty-wrapper {
    text-align: left;
}

.loyalty-wrapper li {
    list-style-type: decimal;
}

.loyalty-wrapper li li {
    list-style-type: disc;
}

.loyalty-image {
    
    -webkit-box-shadow: 0 0 3px rgba(0,152,218,0.3);
    box-shadow: 0 0 3px rgba(0,152,218,0.3);

    max-width: 100%;

    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 1em;
}

.email-wrapper {
    font-size: 18px;
}

.email-wrapper input {

    background: #4a4a4a80;
    width: 50%;
    height: 100%;
    padding: 0;

    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    height: 34px;
    color: white;

    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.email-wrapper input::placeholder {
    color: #858585;
}

.email-button-browser-font {
    font-size: 18px;
    padding-left:1em;
    padding-right:1em;
}
  
.email-button-mobile-font {
    font-size: 18px;
    width: 80%;
}

.email-message-font {
    font-size: 14px;
}

.account-video{
    width:60vw;
    margin-left: auto;
    margin-right: auto;
}