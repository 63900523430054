.book-now-modal-wrapper{
    text-shadow: none;
    position: fixed;
    top: 0px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    cursor:pointer
}
.book-now-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default;

    background-color: rgba(46, 44, 44, 0.959);
    margin: auto;
    padding: 20px;
    width: 80%;
    white-space: normal;
    border: 3px solid #0098DA;
    box-sizing: border-box;
    border-radius: 16px;
    
    color: #eee;
}
.book-now-modal-heading {
    text-align: center;
    font-size: 20px;
    padding-bottom: 1em;
}
.book-now-modal-title {
    background-color: #545454;
    color: white;
    font-size: 20px;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    position: relative;
    z-index: 0;
}
.book-now-modal-body {
    font-size: 14px;
}