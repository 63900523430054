.featured-games-heading {
    font-size: 34px;
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
}

.featured-game-wrapper {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.featured-games-section-wrapper {
    padding-bottom: 2em;
}