.card-wrapper {
    font-size: 18px;
}

.card-wrapper input {

    background: #4a4a4a80;
    width: 50%;
    height: 100%;
    padding: 0;

    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    height: 34px;
    color: white;

    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.card-wrapper input::placeholder {
    color: #858585;
}

.card-button-browser-font {
    font-size: 18px;
    padding-left:1em;
    padding-right:1em;
}
  
.card-button-mobile-font {
    font-size: 18px;
    width: 80%;
}

.card-message-font {
    font-size: 14px;
}