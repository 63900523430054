
@font-face {
  font-family: 'GoodTimes';
  src: url("./assets/fonts/GoodTimes.ttf");
}
@font-face {
  font-family: 'Furore';
  src: url("./assets/fonts/Furore.otf");
}
/* days-one-regular - latin */
@font-face {
  font-family: 'Days One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/days-one-v10-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
  src: local(''),
       url('./assets/fonts/days-one-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/days-one-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/days-one-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/days-one-v10-latin-regular.eot') format('embedded-opentype'), /* IE9 Compat Modes */
       url('./assets/fonts/days-one-v10-latin-regular.svg#DaysOne') format('svg'); /* Legacy iOS */
}

html {
  overflow-x: hidden;
  scroll-behavior: auto;
  text-shadow: 2px 2px black;
}

button {
  text-shadow: 2px 2px black;
}

body {
  min-height: 100%;

  scrollbar-color: dark #0098da;
  scrollbar-width: thin;
}

a:visited {
  color: #0098DA;
}

.mobile-index-wrapper {
  position: relative;
  background-size: 100%;
  background-color: black;

  min-height: 75%;
}

.index-wrapper {
  position: relative;
  background-size: 100%;
  background-color: black;

  min-height: 75%;
}
/* Scrollbar stuff */
body::-webkit-scrollbar{
  width:7px;
  height:7px;
}
body::-webkit-scrollbar-track{
  background-color: #000000;
}
body::-webkit-scrollbar-thumb{
  background: linear-gradient(to bottom, #0098DA, rgb(0, 124, 177));
  border-radius: 10px;
  padding-top: 4px;
  padding-bottom: 4px;

}

.site-wrapper {
  color: white;
  font-family: 'Days One', sans-serif;
  min-height: 100vh;
  background-size: 100%;
  background-color: black;
  margin-bottom: 170px;
}

.mobile-site-wrapper {
  color: white;
  font-family: 'Days One', sans-serif;
  min-height: 100vh;
  background-size: 100%;
  background-color: black;
  margin-bottom: 298px;
}

.height-wrapper {
  min-height: 75%;
}

.larger-paragraph {
  padding-left: 2em;
  padding-right: 2em;
  text-align: justify;
  font-size: 24px;
  padding-bottom: 2em;
  justify-content: center;
}

.full-width-padding {
  padding-left: 7em;
  padding-right: 7em;
}

.home-page-video {
  margin-left: auto;
  margin-right: auto;
}

.paragraph {
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 1em;
  text-align: center;
  font-size: 18px;
}

h3 {
  font-size: 20px;
}

.alignment-wrapper {
  text-align: center;
}

.add-pulse-animation {
  animation: BookNowPulse 3s infinite;
}

.news-letter-button {
  color: white;
  text-align: center;
  border-radius: 8px;
  border: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: 1em;
  font-family: 'Days One', sans-serif;
}

.disabled-letter-button {
  background: linear-gradient(0deg, #666666, #949494);
}

.enabled-letter-button {
  background: linear-gradient(0deg, #00577d, #0098DA);
}

.enabled-letter-button:hover {
  background: linear-gradient(0deg, #00577d, #00577d);
  cursor: pointer;
}

.ctrlv-blue-button-browser {
  background: linear-gradient(0deg, #00577d, #0098DA);
  color: white;
  font-size: 21px;
  text-align: center;
  border-radius: 8px;
  width: 20%;
  border: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: 1em;
  font-family: 'Days One', sans-serif;
}

.ctrlv-blue-button-browser:hover {
  cursor: pointer;
  background: linear-gradient(0deg, #00577d, #00577d);
}

.ctrlv-blue-button {
  background: linear-gradient(0deg, #00577d, #0098DA);
  color: white;
  font-size: 18px;
  text-align: center;
  border-radius: 8px;
  width: 50%;
  border: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: 1em;
  font-family: 'Days One', sans-serif;
}

.ctrlv-blue-button:hover {
  cursor: pointer;
  background: linear-gradient(0deg, #00577d, #00577d);
}

a {
  color: #0098DA;
  text-decoration: none;
}

input {
  font-family: 'Days One', sans-serif;
}

.remove-shadow {
  text-shadow: none;
}

.blue-text {
  color: #0098DA;
}

.youtube-text {
  color: #ff0000;
}

.facebook-text {
  color: #3366ff;
}

.twitch-text {
  color: #7500db;
}

.instagram-text {
  color: #ff00ff;
}



.youtube-iframe {
  width: 100%;
  height: 100%;
}

.footer-parallax-wrapper {
  position: fixed;
  bottom: 0;
  z-index: -1;
  background-color: #000;
  width:100%;
  padding-top: 100%;
}

.parallax-wrapper {
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

.show-parallax-img {
  opacity: 1;
  transition: opacity 2s;
}

.hide-parallax-img {
  opacity: 0;
}

.gradient-background {
  background: linear-gradient(180deg, #0072a3, #0072a3);
}