.coverage-index-button {
    background-color: #0098DA;
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 1em;
    transition: 0.5s;
    font-family: 'Days One', sans-serif;
}

.coverage-index-button:hover {
    cursor: pointer;
    background-color: #007bb0;
    transition: 0.5s;
}

.coverage-index-button:disabled {
    background-color:#969696;
    color: #4d4d4d;
    transition: 0.5s;
    cursor: default;
    text-shadow: none;
}



.panel-wrapper {
    display: flex;
}