.footer-links {
    font-size: 16px;
    text-align: center;
}

.footer-copyright {
    font-size: 16px;
}

.mobile-footer-padding {
    width: 100vw;
    padding: 1em 0em 6em 0em;
    background-color: #1e1e1e;
}

.footer-wrapper {
    width: 100vw;
    padding: 1em;
    background-color: #1e1e1e;
}

.footer-paragraph  {
    padding-left: 2em;
    padding-right: 2em;
    text-align: center;
    font-size: 18px;
}