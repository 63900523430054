@import url(https://fonts.googleapis.com/css?family=Days+One);
body {
  margin: 0;
}


@font-face {
  font-family: 'GoodTimes';
  src: url(https://ctrlv.ca/static/media/GoodTimes.77211513.ttf);
}
@font-face {
  font-family: 'Furore';
  src: url(https://ctrlv.ca/static/media/Furore.7c2d2443.otf);
}
/* days-one-regular - latin */
@font-face {
  font-family: 'Days One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ctrlv.ca/static/media/days-one-v10-latin-regular.ce3f56a2.ttf) format('truetype'); /* Safari, Android, iOS */
  src: local(''),
       url(https://ctrlv.ca/static/media/days-one-v10-latin-regular.81cd28ce.eot?#iefix) format('embedded-opentype'), 
       url(https://ctrlv.ca/static/media/days-one-v10-latin-regular.5ccc870e.woff2) format('woff2'), 
       url(https://ctrlv.ca/static/media/days-one-v10-latin-regular.da2e1b32.woff) format('woff'), 
       url(https://ctrlv.ca/static/media/days-one-v10-latin-regular.81cd28ce.eot) format('embedded-opentype'), 
       url(https://ctrlv.ca/static/media/days-one-v10-latin-regular.a61029c3.svg#DaysOne) format('svg'); /* Legacy iOS */
}

html {
  overflow-x: hidden;
  scroll-behavior: auto;
  text-shadow: 2px 2px black;
}

button {
  text-shadow: 2px 2px black;
}

body {
  min-height: 100%;

  scrollbar-color: dark #0098da;
  scrollbar-width: thin;
}

a:visited {
  color: #0098DA;
}

.mobile-index-wrapper {
  position: relative;
  background-size: 100%;
  background-color: black;

  min-height: 75%;
}

.index-wrapper {
  position: relative;
  background-size: 100%;
  background-color: black;

  min-height: 75%;
}
/* Scrollbar stuff */
body::-webkit-scrollbar{
  width:7px;
  height:7px;
}
body::-webkit-scrollbar-track{
  background-color: #000000;
}
body::-webkit-scrollbar-thumb{
  background: linear-gradient(to bottom, #0098DA, rgb(0, 124, 177));
  border-radius: 10px;
  padding-top: 4px;
  padding-bottom: 4px;

}

.site-wrapper {
  color: white;
  font-family: 'Days One', sans-serif;
  min-height: 100vh;
  background-size: 100%;
  background-color: black;
  margin-bottom: 170px;
}

.mobile-site-wrapper {
  color: white;
  font-family: 'Days One', sans-serif;
  min-height: 100vh;
  background-size: 100%;
  background-color: black;
  margin-bottom: 298px;
}

.height-wrapper {
  min-height: 75%;
}

.larger-paragraph {
  padding-left: 2em;
  padding-right: 2em;
  text-align: justify;
  font-size: 24px;
  padding-bottom: 2em;
  -ms-flex-pack: center;
      justify-content: center;
}

.full-width-padding {
  padding-left: 7em;
  padding-right: 7em;
}

.home-page-video {
  margin-left: auto;
  margin-right: auto;
}

.paragraph {
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 1em;
  text-align: center;
  font-size: 18px;
}

h3 {
  font-size: 20px;
}

.alignment-wrapper {
  text-align: center;
}

.add-pulse-animation {
  -webkit-animation: BookNowPulse 3s infinite;
          animation: BookNowPulse 3s infinite;
}

.news-letter-button {
  color: white;
  text-align: center;
  border-radius: 8px;
  border: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: 1em;
  font-family: 'Days One', sans-serif;
}

.disabled-letter-button {
  background: linear-gradient(0deg, #666666, #949494);
}

.enabled-letter-button {
  background: linear-gradient(0deg, #00577d, #0098DA);
}

.enabled-letter-button:hover {
  background: linear-gradient(0deg, #00577d, #00577d);
  cursor: pointer;
}

.ctrlv-blue-button-browser {
  background: linear-gradient(0deg, #00577d, #0098DA);
  color: white;
  font-size: 21px;
  text-align: center;
  border-radius: 8px;
  width: 20%;
  border: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: 1em;
  font-family: 'Days One', sans-serif;
}

.ctrlv-blue-button-browser:hover {
  cursor: pointer;
  background: linear-gradient(0deg, #00577d, #00577d);
}

.ctrlv-blue-button {
  background: linear-gradient(0deg, #00577d, #0098DA);
  color: white;
  font-size: 18px;
  text-align: center;
  border-radius: 8px;
  width: 50%;
  border: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: 1em;
  font-family: 'Days One', sans-serif;
}

.ctrlv-blue-button:hover {
  cursor: pointer;
  background: linear-gradient(0deg, #00577d, #00577d);
}

a {
  color: #0098DA;
  text-decoration: none;
}

input {
  font-family: 'Days One', sans-serif;
}

.remove-shadow {
  text-shadow: none;
}

.blue-text {
  color: #0098DA;
}

.youtube-text {
  color: #ff0000;
}

.facebook-text {
  color: #3366ff;
}

.twitch-text {
  color: #7500db;
}

.instagram-text {
  color: #ff00ff;
}



.youtube-iframe {
  width: 100%;
  height: 100%;
}

.footer-parallax-wrapper {
  position: fixed;
  bottom: 0;
  z-index: -1;
  background-color: #000;
  width:100%;
  padding-top: 100%;
}

.parallax-wrapper {
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

.show-parallax-img {
  opacity: 1;
  transition: opacity 2s;
}

.hide-parallax-img {
  opacity: 0;
}

.gradient-background {
  background: linear-gradient(180deg, #0072a3, #0072a3);
}
.menu-icon-div {
    width: 70%;
    height: 3px;
    background-color: white;
    margin-bottom: 6px;
}

.mobile-menu-icon {
    position: absolute;
    width: 100px;
    text-align: end;
    top: calc(33px / 2);
    right: 2em;
}

.mobile-menu-block{
    display:-ms-flexbox;
    display:flex;
    text-align: end;
    cursor: pointer;
}

.mobile-menu-block:hover .menu-icon-div{
    background-color: #0098da;
    transition: background-color .15s;
}
.mobile-menu-block:hover .mobile-menu-title{
    color:#0098da;
    transition: color .15s;
}

.mobile-menu-hamburger{
    -ms-flex:3 1;
        flex:3 1;
}
.mobile-menu-hamburger:hover .menu-icon-div{
    background-color: #0098da;
}
.mobile-menu-title{
    -ms-flex:1 1;
        flex:1 1;
    padding-right: 15px;
}

.link-hover:hover {
    cursor: pointer;
}

@media (min-width: 700px) {
    .mobile-menu-icon {
        top: 36.5px;
    }
}

@media (min-width: 1500px) {
    .mobile-menu-icon {
        display: none;
    }
}
.side-tray-wrapper {
    position: fixed;
    top: 100px;
    width: 90%;

    background: linear-gradient(90deg, #4B4B4D -3.15%, #242425 99.46%);
    border: 2px solid #000000;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px;

    z-index: 9;

    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 100px);
}

.side-tray-wrapper a, .side-tray-wrapper a:visited {
    color: white;
}

.side-tray-wrapper .sub-menu li {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 35px;
}

.side-tray-wrapper .sub-sub-menu li {
    padding-left: 45px;
}

.side-tray-wrapper ul {
    padding: 0;
    margin: 0;
}

.side-tray-wrapper li {
    font-size: 20px;
    line-height: 26px;

    width: 100%;
    border: 1px solid black;

    list-style-type: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
}

.open-side-tray {
    margin-left: 10%;
    transition: margin 0.3s;
}

.closed-side-tray {
    margin-left: 190%;
    transition: margin 0.3s;
}

.freevr-side-tray-sub-item {
    height: 152px;
    transition: 0.3s;
}

.corperate-hide-franchsing {
    height: 190px;
    transition: 0.3s;
}

.corperate-side-tray-sub-item {
    height: 152px;
    transition: 0.3s;
}

.legal-side-tray-sub-item {
    height: 152px;
    transition: 0.3s;
}

.my-info-side-tray-sub-item {
    height: 76px;
    transition: 0.3s;
}

.waiver-video-side-tray-sub-item {
    height: 76px;
    transition: 0.3s;
}

/* height is a multiple of 38px x number of elements in <ul> */
.locations-side-tray-sub-item {
    height: 342px;
    transition: 0.3s;
}

.closed-side-tray-sub-item {
    height: 0px;
    overflow: hidden;
    transition: 0.3s;
}

.side-tray-sub-menu-dropdown {
    position:absolute;
    right: 15%;
    top: calc(50% - 7px);
    width: 14px;
    height: 14px;
}

.sub-menu-li {
    position: relative;
}


.search{
    background: linear-gradient(to bottom, #0098da -3.15%, #005c83 99.46%);
}
.search-dropdown-arrow{
    position: absolute;
    height: 70px;
    width:100%;

    pointer-events: none;

    box-sizing: border-box;
}

@media (min-width: 1500) {
    .side-tray-wrapper {
        display: none;
    }
}

@media (max-width: 700px) {
    .side-tray-wrapper {
        top: 60px;
    }
    
}

.searchIcon {
    width: 40%;
    padding-top: 32px;
}

.searchIcon:hover {
    cursor: pointer;
}
.searchText:hover{
    cursor: pointer;
}

.search-control-wrapper {
    position: absolute;
    top: 100px;
    right: 0px;
    text-align: right;
    z-index: 500;
    background: linear-gradient(90deg, #4B4B4D -3.15%, #242425 99.46%);
    border-top: 3px solid #0098da;
    width: 350px;
}

.inline-search-wrapper{
    width:100%;
}

.search-controls {
    padding: 1em;
}

.search-textbox {
    background: #4a4a4a80;
    width: 100%;
    height: 100%;
    padding: 0;

    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    height: 34px;
    color: white;

    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.search-button {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;

    font-family: 'Days One', sans-serif;
}

.inline-search-textbox {
    background: #4a4a4a80;
    color:white;

    width: 60%;

    border: 2px solid #ffffff;
    border-radius: 4px;

    padding-top:0.5em;
    padding-bottom:0.4em;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}
.inline-search-button {
    background: linear-gradient(0deg, #00435f, rgb(0, 168, 240));
    color: white;
    font-size: 15px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.5em;
    padding-left: 0.5em;

    margin-left: 1em;

    font-family: 'Days One', sans-serif;
}

.search-button:hover { 
    background: linear-gradient(0deg, #00577d, #00577d);
    cursor: pointer;
}

.search-result {
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 40px;
    padding-left: 40px;
    list-style-type: none;
    text-align: left;
}

.search-result:hover {
    background: linear-gradient(90deg, #0098da -3.15%, #242425 99.46%);
}
.search-result-list{
    max-height: 12em;
    overflow-y: auto;
}
.search-result-list-inline{
    max-height: 8em;
    overflow-y: scroll;
    margin-right: 3px;

    width:calc(100% - 30px);

    /*
    */
    scrollbar-width: thin !important;
    scrollbar-color: #242425 #eeeeee77 ;
}

.search-result-list-inline::-webkit-scrollbar{
    width: 5px !important;
}
.search-result-list-inline::-webkit-scrollbar-track{
    background-color: #eeeeee77;

    border-radius: 5px;
}
.search-result-list-inline::-webkit-scrollbar-thumb{
    background-color: #242425;
    border-radius: 36px;
}


.search-result-list-inline .search-result{
   padding-left: 0px; 
   padding-right: 0px;


   border-bottom: 1px solid #002231;
}
.book-now-modal-wrapper{
    text-shadow: none;
    position: fixed;
    top: 0px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    cursor:pointer
}
.book-now-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: default;

    background-color: rgba(46, 44, 44, 0.959);
    margin: auto;
    padding: 20px;
    width: 80%;
    white-space: normal;
    border: 3px solid #0098DA;
    box-sizing: border-box;
    border-radius: 16px;
    
    color: #eee;
}
.book-now-modal-heading {
    text-align: center;
    font-size: 20px;
    padding-bottom: 1em;
}
.book-now-modal-title {
    background-color: #545454;
    color: white;
    font-size: 20px;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    position: relative;
    z-index: 0;
}
.book-now-modal-body {
    font-size: 14px;
}
.desktop-nav-wrapper {
    text-align: center;
    height: 100%;
    display: none;
    position: absolute;
    right: 0;
    font-size: 16px;
    z-index: 6;
}

.heading-book-now {
    -webkit-animation: BookNowPulse 3s infinite;
            animation: BookNowPulse 3s infinite;
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    border: none;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 15px;
    font-size: 18px;
    font-family: 'Days One', sans-serif;
}

.heading-book-now:hover {
    background: linear-gradient(0deg, #00577d, #00577d);
    cursor: pointer;
}


@-webkit-keyframes BookNowPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}


@keyframes BookNowPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.desktop-nav-book-now {
    height: calc(100% - 37px);

    padding-top: 28px;
    margin-right: 25px;
}

.desktop-nav-item {
    color: white;
    height: calc(100% - 37px);

    padding-top: 37px;
    margin-right: 25px;
}

.desktop-nav-item:hover {
    cursor: pointer;
    color: #0098da;
    border-top: 3px solid #0098da;
}

.desktop-sub-item a, .desktop-sub-item a:visited {
    color: white;
} 

.free-vr-desktop-sub-item a, .free-vr-desktop-sub-item a  {
    
    color: white;
}

.free-vr-desktop-sub-item {
    position: absolute;
    top: 100px;
    opacity: 0;
    color: white;
    transition: 0.25s;
    border-top: 3px solid #0098da;
    visibility: hidden;
}

.corporate-desktop-sub-item {
    width: 350px;
    position: absolute;
    top: 100px;
    right: 0px;
    opacity: 0;
    color: white;
    transition: 0.25s;
    border-top: 3px solid #0098da;
    visibility: hidden;
}

.my-info-desktop-sub-item {
    width: 350px;
    position: absolute;
    top: 100px;
    opacity: 0;
    color: white;
    transition: 0.25s;
    border-top: 3px solid #0098da;
    visibility: hidden;
}

.waiver-video-desktop-sub-item {
    width: 350px;
    position: absolute;
    top: 100px;
    opacity: 0;
    color: white;
    transition: 0.25s;
    border-top: 3px solid #0098da;
    visibility: hidden;
}

.location-desktop-sub-item {
    width: 350px;
    position: absolute;
    top: 100px;
    opacity: 0;
    color: white;
    transition: 0.25s;
    border-top: 3px solid #0098da;
    visibility: hidden;
}

.free-vr-desktop-item:hover ~ .free-vr-desktop-sub-item {
    opacity: 1;
    visibility: visible;
    transition: 0.75s;
}
.free-vr-desktop-sub-item:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.waiver-video-desktop-item:hover ~ .waiver-video-desktop-sub-item {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.my-info-desktop-item:hover ~ .my-info-desktop-sub-item {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.location-desktop-item:hover ~ .location-desktop-sub-item {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.my-info-desktop-sub-item:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.waiver-video-desktop-sub-item:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.location-desktop-sub-item:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.corporate-desktop-item:hover ~ .corporate-desktop-sub-item {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}
.corporate-desktop-sub-item:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.free-vr-desktop-sub-item ul, .corporate-desktop-sub-item ul, .my-info-desktop-sub-item ul, .waiver-video-desktop-sub-item ul, .location-desktop-sub-item ul {
    background: linear-gradient(90deg, #4B4B4D -3.15%, #242425 99.46%);
    border: 2px solid #000000;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px;
    margin: 0;
    padding: 0;
}

.free-vr-desktop-sub-item li, .corporate-desktop-sub-item li, .my-info-desktop-sub-item li, .waiver-video-desktop-sub-item li, .location-desktop-sub-item li {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 40px;
    padding-left: 40px;
    list-style-type: none;
    text-align: left;
}

.free-vr-desktop-sub-item li:hover, .corporate-desktop-sub-item li:hover, .my-info-desktop-sub-item li:hover, .waiver-video-desktop-sub-item li:hover, .location-desktop-sub-item li:hover {
    background: linear-gradient(90deg, #0098da -3.15%, #242425 99.46%);
    transition: 0.25s;
}

.legal-desktop-sub-menu {
    width: 300px;
    position: absolute;
    left: -298px;
    top: 162px;
    opacity: 0;
    border-top: 3px solid #0098da;
    transition: 0.25s;
    visibility: hidden;
}

.legal-desktop-sub-item:hover ~ .legal-desktop-sub-menu {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.legal-desktop-sub-menu:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.75s;
    visibility: visible;
}

.sub-menu-dropdown {
    float: right;
}


@media (min-width: 1500px) {
    .desktop-nav-wrapper {
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
}

.dropdown-arrow {
    padding-left: 1em;
    width: 14px;
    height: 14px;
}

.main-header {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    height: 100px;
    left: 0px;
    top: 0px;
    
    background: linear-gradient(180deg,hsla(75,0%,100%,.3),hsla(0,0%,100%,0)),rgba(25, 25, 25, 0.9);
    z-index: 9;
}

.logo {
    padding-left: 20px;
    padding-top: 3px;

    width: auto;
    height: 70px;
}

@media (max-width: 700px) {
    .main-header {
        height: 60px;
    }
    .logo {
        height: 45px;
        width: auto;
    }
}
.social-icon-wrapper { 
    display: -ms-flexbox; 
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
}

.social-icon {
    opacity: 0.6;
}

@media (min-width: 850px) {
    .social-icon-wrapper {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}

.language:not(:first-child)::before {
    content: "|\00a0\00a0"
}
.language:not(:last-child)::after {
    content: "\00a0\00a0"
}

.language:hover {
    cursor: pointer;
}
.footer-links {
    font-size: 16px;
    text-align: center;
}

.footer-copyright {
    font-size: 16px;
}

.mobile-footer-padding {
    width: 100vw;
    padding: 1em 0em 6em 0em;
    background-color: #1e1e1e;
}

.footer-wrapper {
    width: 100vw;
    padding: 1em;
    background-color: #1e1e1e;
}

.footer-paragraph  {
    padding-left: 2em;
    padding-right: 2em;
    text-align: center;
    font-size: 18px;
}
.locations-wrapper {
    padding: 1em;
    font-size: 18px;
    text-align: center;
}

.locations-heading, .country-list-title {
    font-size: 2.75em;
    padding-top: 1em;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
}

.locations-sub-heading, .province-list-title {
    font-size: 2em;
    margin-top: 0.75em;
    text-decoration: underline;
}

/* Province Grid Centering Logic */
.country-list {
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
}

.country-list-content {
    display: grid;
    grid-template-columns:repeat(auto-fit, minmax(max(425px,100%/3),1fr));
}

.province-list-wrapper {
    width: 425px;
    margin: auto;
}

.province-list {
    width: 100%;
}
/* End of Grid Logic */

.locations-map-wrapper {
    vertical-align: top;
    display: inline-block;
    width: 50%;
    height: calc(100vh - 176px);
}

.locations-list-wrapper {   
    vertical-align: top;
    display: inline-block;
    width: 70%;
}

.location-list-item {
    font-size:1.7em;
    color: #0098da;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
}

.location-list-button {
    background: linear-gradient(0deg, #000000, #000000);
    transition: 0.5s;
    box-shadow:10px 10px 0 rgba(0,0,0,.5);
    display:inline-block;
    padding:.2em 1em;
    text-decoration:none;
    box-sizing: border-box;
}

.location-list-button:hover {
    background: linear-gradient(0deg, #0098da, #0098da);
    color: white;
    transition: 0.5s;
    
    -webkit-animation: PulseAndCollapse 3s infinite;
    
            animation: PulseAndCollapse 3s infinite;
}

.location-list-revshare{
    background: linear-gradient(0deg, #003b88, #005eda) !important;
}

.location-list-revshare:hover{
    background: linear-gradient(0deg, #003b88, #003b88) !important;
}

.desktop-list-size {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.mobile-list-size {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@-webkit-keyframes PulseAndCollapse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
}

@keyframes PulseAndCollapse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
}

.parallelogram {
  -webkit-transform: skew(-20deg);
      -ms-transform: skew(-20deg);
          transform: skew(-20deg);
  border: 1em;
  border-width: 0.1em;
  border-style: outset;
  border-color: white;
  border-radius: 2rem;
}

.skew-fix {
    -webkit-transform: skew(20deg);
        -ms-transform: skew(20deg);
            transform: skew(20deg);
    display:inline-block;
    color: #0098da;
}

.bottom-spacing {
    margin-bottom: 4em;
}


@media (max-width: 900px) {
    .location-list-item {
        font-size:1.5em;
        color: #0098da;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .locations-list-wrapper {   
        vertical-align: top;
        display: inline-block;
        width: 80%;
    }
}
.CheckBox-outer {
    width: 100%;
    margin-top: 5px;
}

.CheckBox-div {
    box-sizing: border-box;
    margin: 0 auto;
    width: 90%;
    text-align: center;
}

.CheckBox-span {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid black;
    box-sizing: border-box;
    display: block;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    margin-right: 0.5em;
    padding-top: 1px;
    cursor: pointer;
    background-color: #fff;
    color: #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
}

.CheckBox-input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.CheckBox-label {
    font-weight: 300 !important;
    margin-bottom: 0px;
}

.CheckBox-label .CheckBox-input:checked ~ .CheckBox-span {
    background-color: #0098DA;
}

.detected-location-modal-wrapper {
    position: fixed; /* Stay in place */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
}

.detected-location-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    background-color: rgba(255,255,255,0.9);
    margin: auto;
    padding: 20px;
    width: 60%;
    white-space: normal;
    border: 3px solid #0098DA;
    box-sizing: border-box;
    border-radius: 16px;
}

.detected-location-modal-section {
    font-size: 24px;
    color: #0098DA;
    text-shadow: none;
    text-align: center;
}

.detected-location-cache-checkbox {
    font-size: 20px;
    color: #0098DA;
    text-align: center;
}

.show-modal {
    opacity: 1;
    visibility: show;
    transition: 1s;
}

.hide-modal {
    opacity: 0;
    visibility: hidden;
    transition: 1s;
}

.treVR-image {
    max-width: 80%;
}

.mobile-treVR-image {
    width: 50%;
}

.detected-popup-or {
    text-align: center;
    color: #0098DA;
    text-shadow: none;
}

.trevr-column {
    width: 30%;
    display: inline-block;  
    vertical-align: middle;
    text-align: center;
}

.options-column {
    width: 70%;
    display: inline-block;  
    vertical-align: middle;
    text-align: left;
}

@media (max-width: 1000px) {
    .detected-location-modal {
        width: 90%;
        max-height: 80%;
        overflow-y: scroll;
    }
}
/* outer div */
.FacilityHours {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  line-height: 1;
  /* desktop: 16px, mobile: 13px */
  font-size: 16px;
  font-family: "Days One", "Trebuchet MS", Helvetica, sans-serif;
  background: rgba(0, 0, 0, 0);
  color: #edeaea;

  /* simulate iframe box for manual centering desktop: 189px, mobile: 117px
  margin: auto;
  box-sizing: content-box;
  height: 189px;
  width: 500px; */
}

.facility-hour-padding {
  margin-bottom: 5px;
  margin-top: 5px;
}





.faq-modal-wrapper {
    text-shadow: none;
    position: fixed;
    top: 0px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
}

.faq-modal-wrapper:hover {
    cursor: pointer;
}

.faq-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    background-color: #222222ee;
    margin: auto;
    padding: 20px;
    width: 80%;
    white-space: normal;
    border: 3px solid #0098DA;
    box-sizing: border-box;
    border-radius: 16px;
    
    color: #eee;
}

.faq-modal:hover {
    cursor: default;
}

.show-faq-modal {
    opacity: 1;
    visibility: show;
    transition: 0.5s;
}

.hide-faq-modal {
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
}

.faq-section-heading {
    text-align: center;
    font-size: 20px;
    padding-bottom: 1em;
}

.faq-section-title-wrapper {
    width: calc(100% - 36px);
}

.faq-section-title {
    background-color: #545454;
    color: white;
    -webkit-text-stroke: .5px black;
    font-size: 20px;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    position: relative;
    z-index: 0;
}

.faq-section-title:hover {
    background-color: #0098DA;
    color: white;
    cursor: pointer;
}

.faq-section-body {
    font-size: 14px;
}

.faq-section-body a {
    color: #0098DA;
}

.expand-icon {
    font-size: 28px;
    position: absolute;
    right: 0;
    top: 10%;
    vertical-align: top;
    padding-right: 1em;
}

.close-button:hover{ 
    cursor: pointer;
    background-color: #32b5ee;
    box-shadow: none;
}

.close-button {
    text-align: center;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    float: right;
    background-color: #0098da;
    color: white;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border-color:#ccc;
    border-radius: 9px;
    box-shadow: 1px 1px 2px 0px #545454;
}
.image-slider-wrapper {
    position: relative;
    width:100%;
    height:calc(100vw/2.38);
    overflow: hidden;
}

.desktop-image-slider-wrapper {
    background: linear-gradient(to bottom, #111, #000);
    position: relative;
    width: 100%;
    height: 100%;
}

.desktop-image-slider-wrapper .image-slider-image {
    text-align: center;
    background: linear-gradient(to bottom, #111, #000);
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    left:50%;
    position: absolute;
    top: 0px;
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
    height: 100%;
}

.desktop-image-slider-wrapper .image-slider-image-2 {
    text-align: center;
    left:50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    background: linear-gradient(to bottom, #111, #000);
    position: absolute;
    top: 0px;
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
    height: 100%;
}
@media(max-width:600px){
    .desktop-image-slider-wrapper .image-slider-image {
        -o-object-fit: fill;
           object-fit: fill;
        background-color: #111;
    }
    .desktop-image-slider-wrapper .image-slider-image-2 {
        -o-object-fit: fill;
           object-fit: fill;
        background-color: #111;
    }
}
.image-transition-show {
    opacity: 1;
    transition: 1s;
}

.image-transition-hide {
    opacity: 0;
    visibility: hidden;
}

.mobile-image-slider-wrapper {
    position: relative;
    overflow: hidden;
}

.mobile-image-slider-wrapper .image-slider-image {
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    -o-object-fit: fill;
       object-fit: fill;
}

.mobile-image-slider-wrapper .image-slider-image-2 {
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    position: absolute;
    top: 0px;
    -o-object-fit: fill;
       object-fit: fill;
}

.image-slider-controls-wrapper {
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
            transform: translate(-50%);
}

.image-slider-controls-wrapper button {
    display: inline-block;

    text-align: center;
    border-radius: 8px;
    border: none;
    padding: 0.5em;
    margin: 0.25em;
    margin-bottom: 1em;
}

.image-slider-controls-wrapper button:hover {
    cursor: pointer;
}

.image-slider-controls-wrapper .selected-button {
    background-color: #727376;
}

.image-slider-controls-wrapper .not-selected-button {
    background-color: #C4C4C4;
}
.aimage-slider-wrapper {
    position: relative;
    width:100%;
    height:calc(100vw/3.38);
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom: 2.5rem;
}

.adesktop-image-slider-wrapper {
    background: linear-gradient(to bottom, #000, #000);
    position: relative;
    width: 100%;
    height: 100%;
}

.adesktop-image-slider-wrapper .aimage-slider-image {
    text-align: center;
    background: linear-gradient(to bottom, #000, #000);
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    left:50%;
    position: absolute;
    top: 0px;
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
    height: 100%;
}

.adesktop-image-slider-wrapper .aimage-slider-image-2 {
    text-align: center;
    left:50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    background: linear-gradient(to bottom, #000, #000);
    position: absolute;
    top: 0px;
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
    height: 100%;
}
@media(max-width:600px){
    .adesktop-image-slider-wrapper .aimage-slider-image {
        -o-object-fit: fill;
           object-fit: fill;
        background-color: #000;
    }
    .desktop-image-slider-wrapper .aimage-slider-image-2 {
        -o-object-fit: fill;
           object-fit: fill;
        background-color: #000;
    }
}
.aimage-transition-show {
    opacity: 1;
    transition: 1s;
}

.aimage-transition-hide {
    opacity: 0;
    visibility: hidden;
}

.amobile-image-slider-wrapper {
    position: relative;
    overflow: hidden;
}

.amobile-image-slider-wrapper .aimage-slider-image {
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    -o-object-fit: fill;
       object-fit: fill;
}

.amobile-image-slider-wrapper .aimage-slider-image-2 {
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    position: absolute;
    top: 0px;
    -o-object-fit: fill;
       object-fit: fill;
}

.aimage-slider-controls-wrapper {
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
            transform: translate(-50%);
}

.aimage-slider-controls-wrapper button {
    display: inline-block;

    text-align: center;
    border-radius: 8px;
    border: none;
    padding: 0.5em;
    margin: 0.25em;
    margin-bottom: 1em;
}

.aimage-slider-controls-wrapper button:hover {
    cursor: pointer;
}

.aimage-slider-controls-wrapper .aselected-button {
    background-color: #727376;
}

.aimage-slider-controls-wrapper .anot-selected-button {
    background-color: #C4C4C4;
}
.featured-games-heading {
    font-size: 34px;
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
}

.featured-game-wrapper {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.featured-games-section-wrapper {
    padding-bottom: 2em;
}


.featured-game-padding {
    margin-left: 1em;
    margin-right: 1em;
    position: relative;
}

.featured-game {
    display: inline-block;
    width: calc(100% / 5);
}

.featured-game-image:hover {
    cursor: pointer;
}
.newsletter-wrapper {
    font-size: 18px;
}

.newsletter-wrapper input {

    background: #4a4a4a80;
    width: 100%;
    height: 100%;
    padding: 0;

    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    height: 34px;
    color: white;

    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.newsletter-wrapper input::-webkit-input-placeholder {
    color: #858585;
}

.newsletter-wrapper input::-moz-placeholder {
    color: #858585;
}

.newsletter-wrapper input:-ms-input-placeholder {
    color: #858585;
}

.newsletter-wrapper input::-ms-input-placeholder {
    color: #858585;
}

.newsletter-wrapper input::placeholder {
    color: #858585;
}

.birthday-wrapper input {
    width: 20%;
    margin-right: 1em;
}
.openBackdrop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background-color: #33333395;
}
.openBackdrop1 {
    display:none;
}
.openPositionWrapper{
    width: 85%;
    height: 100%;
    position: fixed;
    top: 10%;
    left: 6%;
    padding: 1% 25% 1% 25%;
    box-sizing: border-box;
}
.openText1{
    width: 100%;
    position:relative;
    height: 33%;
    display: block;
    box-sizing: border-box;
    padding: 5% 1% 3% 1%;
    font-size: 28pt;
}
.openText2{
    width: 100%;
    position:relative;
    height: 33%;
    display: block;
    box-sizing: border-box;
    padding: 3% 1% 3% 1%;
    font-size: 16pt;
}
.blueText{
    color: #0098da;
}
.openImage{
    width:75%;
    position:relative;
    display:block;
    padding: 0 0 0 12.5%;
}
.openMain{
    position: relative;
    display:block;
    background-color: #000000ee;

    text-align: center;

    box-sizing: border-box;
    border: 2px solid #333333;
    border-radius: 5px;

    color: #ffffff;
    border: 2px solid #0098da;

    border-radius: 5px;
}
.openCloseButton{
    position: absolute;
    top:2px;
    right:2px;
    width:1.1em;
    height: 1.1em;

    text-align: center;

    background-color: #0098da;
    color: #ffffff;

    border: 1px solid #cccccc;
    padding:3px;
    border-radius: 5px;

    box-shadow: 2px 2px 3px 1px #111111;

}
.openCloseButton:hover{
    background-color: #00abf5;
    box-shadow: 1px 1px 3px 0px #111111;
}

@media(max-width:1000px){
    .openWrapper{
        padding:0px;
    }
}
.location-padding-wrapper{
    padding-left: 2.5%;
    padding-right: 2.5%;
}

.package-gallery  { 
    text-align: center;
    padding-bottom: 1em;
}

.location-section-header {
    font-size: 20px;
    padding-bottom: 1em;
    text-align: center;
}

.location-location-line {
    font-size: 18px;
    color: #0098DA;
    padding-bottom: 1em;
}

.location-location-line a {
    color: #0098DA;
}

.location-hour-row {
    font-size: 18px;
    padding-bottom: 0.25em;
}

.hours-wrapper {
    padding-bottom: 1em;
}

.location-games-wrapper {
    text-align: center;
}

.games-paragraph {
    text-align:left;
}

.location-title {
    font-size: 24px;
    text-align: center;
    padding: 1em;
}

.facility-hours {
    border: none;
}

.google-review-wrapper .review-text {
    padding-top: 1em;
    padding-bottom: 1em;
}

.review:after {
    content: "";
    
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;

    border-top: 1px solid gray;

    display: block;
    width: 50%;
}

.stars {
    padding-right: 2em;
}

.google-review-wrapper {
    font-size: 18px;
    padding: 1em;
    height: 275px;
    overflow-y: scroll;
    border: 1px solid gray;
    border-radius: 20px;
}

.price-wrapper .location-section-header {
    text-align: center;
}

.review-contact-section {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 2em;
}

.location-review-contact-wrapper {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.location-review-wrapper {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
}

.location-parent-request {
    font-size: 18px;

    text-align: center;
}

.section-wrapper {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.section-wrapper .location-details {
    display: inline-block;
    vertical-align: top;
    width: 45vw;
}

.section-wrapper .map-wrapper {    
    display: inline-block;
    width: 45vw;
    height: 355px;
}

.location-game-scroller-wrapper {
    width: 25%;
    margin: auto;
}

.gallery-cell {
    padding-top: 1em;
    position: relative;
    text-align: center;
    display: inline-block;
    vertical-align: bottom;
    width: 20%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.mobile-gallery-cell {
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
}

.calendar-wrapper {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.calendar-frame-helper {
    height: 540px;
}

.calendar-wrapper iframe {
    border: none;
}

.selected-review-tab {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding: 0.5em;
    margin: 1em;
    font-family: 'Days One', sans-serif;
}

.unselected-review-tab {
    background: linear-gradient(0deg, #666666, #949494);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding: 0.5em;
    margin: 1em;
    font-family: 'Days One', sans-serif;
    transition: 1s;
}

.unselected-review-tab:hover { 
    background: linear-gradient(0deg, #00577d, #0098DA);
    cursor: pointer;
}

.review-wrapper {
    background-color: #1e1e1e;
    border-radius: 25px;
    padding: 1em;
}

.review-description {
    padding-bottom: 1em;
}

.location-book-now-button {
    -webkit-animation: BookNowPulse 3s infinite;
            animation: BookNowPulse 3s infinite;
}

.directions-wrapper {
    padding: 1em;
}

.directions-iframe {
    margin-left: auto;
}

.location-faq {
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
}

.location-faq-browser-width {
    width: 50%;
}

.text-padding {
    padding-left: 2em; 
    padding-right: 2em;
}

.anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

.location-game-section-padding {
    padding-bottom: 2em;
}

.pricing-body{
    font-size:18px;
    padding:10px;
}
.pricing-discounts{
    border-radius: 2px;

    font-size: 23px;


    padding:10px;
}
.pricing-discounts-table{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;

    padding: 3px;

    margin: 10px;
}
.pricing-discounts-table FaqSection{
    margin-left: 5px;
}
.pricing-discounts-footer{
    padding: 0.5em;

    font-style: italic;
    color: #999;
}

.padded{
    padding:10px;
    color:white
}

.spanning{
    width:100%;
    text-align: center;
    box-sizing: border-box;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
}
.warning-gradient{
    background: linear-gradient(to bottom, #FFEB3B , #FFC107);
    padding: 30px;

    cursor: pointer;
    color:black;
    text-shadow: none;
}
.warning-gradient:hover{
    background: none;
    background-color: rgb(223, 167, 0);
}

.stick-to-bottom{
    position:fixed;
    bottom:0;
    z-index: 100;
}

.styled-div{
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
     padding-left:20px;
     padding-right:20px;
}

.atitle {
    font-size: 2.2rem;
    -webkit-text-decoration-color: white;
            text-decoration-color: white;
    text-align: center;
    width: 100%;
    -webkit-margin-before: 2rem;
            margin-block-start: 2rem;
    -webkit-margin-after: 2.5rem;
            margin-block-end: 2.5rem;
}

.atext {
    font-size: 2rem;
    text-align: center;
    width: 100%;
    -webkit-margin-before: 1rem;
            margin-block-start: 1rem;
    -webkit-margin-after: 0.75rem;
            margin-block-end: 0.75rem;
}

@media (max-width: 1150px) {
    .calendar-wrapper {
        width: 100% !important;
    }
}

@media (max-width: 625px) {
    .anchor {
        top: -60px;
    }
}
@media (max-width: 1000px) {
    .pricing-discounts-table .faq-section{
        width:100%;
    }
    .location-faq-browser-width {
        width: 90%;
    }
    
    .location-review-contact-wrapper {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    
    .gallery-cell {
        position: relative;
        display: block;
        width: auto;
        padding-top: 1em;
        padding-bottom: 1em;
    }

}
.location-dropdown-wrapper {
    background-color: rgba(75, 75, 77, 0.3);
    transition: 0.25s;
    border: 1px solid gray;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    -webkit-animation: BookNowPulse 3s infinite;
            animation: BookNowPulse 3s infinite;
}

.location-dropdown-wrapper:hover {
    background-color: #111111;
    transition: 0.25s;
}

.location-dropdown-heading {
    font-size:34px;
    text-align: center;
    color: #0098da;
    padding: 1em;
}

.location-dropdown-heading-open {
    background-color: #111111;
}

.location-dropdown-heading:hover {
    cursor: pointer;
}

.location-show-dropdown {
    max-height: 1000px;
    overflow-y : hidden;
    transition: 0.5s;
    background-color: #111111;
}

.location-hide-dropdown {
    max-height: 0px;
    overflow-y: hidden;
    transition: 0.5s;
}
.stick-to-bottom{
    position:fixed;
    bottom:0;
    z-index: 1000;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 100%;
    padding-top: 35px;
    padding-bottom: 35px;
}

.styled-div{
    padding-left:20px;
    padding-right:20px;
}

.ctrlv-blue-button {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    width: 50%;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 1em;
    font-family: 'Days One', sans-serif;
}

.no-stick{
    width: 70%;
}
.popupBackdrop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;

    background-color: #222222aa;
}
.popupPositionWrapper{
    width: 100%; 
    position: fixed;
    height: 100%;
    box-sizing: border-box;
}
.popupWrapper{
    padding:20px;
    display:block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    z-index: 300;
    box-sizing: border-box;
}
.popupMain{
    position: relative;
    display:block;
    background-color: #444444ee;
    padding: 30px;

    text-align: center;

    box-sizing: border-box;
    border: 2px solid #333333;
    border-radius: 5px;

    color: #ffffff;
    border: 2px solid #0098da;

    border-radius: 5px;
}
.popupCloseButton{
    position: absolute;
    top:2px;
    right:2px;
    width:1.1em;
    height: 1.1em;

    background-color: #0098da;
    color: #ffffff;

    border: 1px solid #cccccc;
    padding:3px;
    border-radius: 5px;

    box-shadow: 2px 2px 3px 1px #111111;

}
.popupCloseButton:hover{
    background-color: #00abf5;
    box-shadow: 1px 1px 3px 0px #111111;
}

@media(max-width:1000px){
    .popupWrapper{
        padding:0px;
    }
}
.GOBackdrop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background-color: #33333395;
}
.GOBackdrop1 {
    display:none;
}
.GOPositionWrapper{
    width: 80%;
    height: 100%;
    position: fixed;
    top: 10%;
    left: 6%;
    padding: 1% 25% 1% 25%;
    box-sizing: border-box;
}
.GOText1{
    width: 100%;
    position:relative;
    height: 33%;
    display: block;
    box-sizing: border-box;
    padding: 5% 1% 3% 1%;
    font-size: 28pt;
}
.GOText2{
    width: 100%;
    position:relative;
    height: 33%;
    display: block;
    box-sizing: border-box;
    padding: 3% 1% 3% 1%;
    font-size: 16pt;
}
.blueText{
    color: #0098da;
}
.GOImage{
    width:100%;
    position:relative;
    display:block;
}
.GOMain{
    position: relative;
    display:block;
    background-color: #000000ee;

    text-align: center;

    box-sizing: border-box;
    border: 2px solid #333333;
    border-radius: 5px;

    color: #ffffff;
    border: 2px solid #0098da;

    border-radius: 5px;
}
.GOCloseButton{
    position: absolute;
    top:2px;
    right:2px;
    width:1.1em;
    height: 1.1em;

    text-align: center;

    background-color: #0098da;
    color: #ffffff;

    border: 1px solid #cccccc;
    padding:3px;
    border-radius: 5px;

    box-shadow: 2px 2px 3px 1px #111111;

}
.GOCloseButton:hover{
    background-color: #00abf5;
    box-shadow: 1px 1px 3px 0px #111111;
}

@media(max-width:1000px){
    .GOWrapper{
        padding:0px;
    }
}

.vertical-align-helper {
    width: 100%;
	display: table-cell;
    vertical-align: middle;
}

.event-section {
    width: 100%;
    min-height: 75vh;
	display: table;
}

.event-right {
    width: 30%;
    padding: 5em;
    margin-left: auto;
}

.event-left {
    width: 30%;
    padding: 5em;
    margin-right: auto;
}

.mobile-event {
    padding: 1em;
}

.birthday-padding {
    padding: 3em;
}

.birthday-video{
    width:60vw;
    margin-left: auto;
    margin-right: auto;
}
.generic-wrapper {  
    padding: 1em;
    font-size: 18px;
    text-align: center;
    overflow-wrap: break-word;
}

.generic-wrapper-no-padding {
    font-size: 18px;
    text-align: center;
    overflow-wrap: break-word;
}

.mobile-wrapper {
    padding: 1em;
}

.browser-wrapper {
    padding: 1em;
    font-size: 18px;
    text-align: center;
    overflow-wrap: break-word;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.browser-section {
    padding: 2em;
}

.face-section {
    padding-left: 1em;
    padding-right: 1em;
}

.mobile-section {
    padding: 2em;
}

.browser-column {
    width: 50%;
    display: inline-block;  
    vertical-align: middle;
    text-align: left;
}

.browser-column-30 {
    width: calc(100% / 3);
    display: inline-block;  
    vertical-align: middle;
}

.browser-column-align-top {
    vertical-align: top;
}

.browser-text-padding {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 2em;
    padding-right: 2em;
    text-align: justify;
}

.center-image {
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.generic-wrapper li, .generic-wrapper-no-padding li {
    text-align: left;
}

.generic-heading {
    font-size: 34px;
}

.game-section-padding {
    padding-top: 1em;
}

.generic-heading-padding {
    padding-top: 1em;
    padding-bottom: 1em;
}

.top-padding {
    padding-top: 1em;
}

.blue-sub-headings .generic-sub-heading {
    color: #0098DA;
}

.generic-sub-heading {
    font-size: 24px;
}

.generic-image {
    box-shadow: 0 0 3px rgba(0,152,218,0.3);
    width: 100%;
}

.parallax-js-image {
    width: 100%;
}

.browser-image {
    width: 50%;
}

.generic-section {
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: justify;

}

.generic-footnote {
    font-size: 16px;
    text-align: center;
    color: #0098da;
    
}

.generic-footnote-white {
    font-size: 14px;
    text-align: center;
    
}

.trevr-img {
    margin-bottom: 2em;
}

.generic-pulse-animation {
    -webkit-animation: BookNowPulse 3s infinite;
            animation: BookNowPulse 3s infinite;
}

@-webkit-keyframes BookNowPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes BookNowPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.generic-location-button {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin: 1em;
    font-family: 'Days One', sans-serif;
}

.generic-rd-button {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.0em;
    padding-left: 1.0em;
    margin: 1em;
    font-family: 'Days One', sans-serif;
}

.generic-location-button:hover { 
    background: linear-gradient(0deg, #00577d, #00577d);
    cursor: pointer;
}

.numbered-list {
    list-style-type: decimal;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg,rgba(0,152,218,0),rgba(0,152,218,.5));
    opacity: 0;
    transition: 1s;
}

.image-overlay:hover {
    background-image: linear-gradient(180deg,rgba(0,152,218,0),rgba(0,152,218,.5));
    opacity: 1;
    transition: 1s;
}

.parallax-js-image-center {
    left: 25% !important;
    top: auto !important;
    bottom: 0 !important;
}

.parallax-js-image-background {
    min-width: 100%;
    height: 100% !important;
}

@media (max-width: 600px) {
    .parallax-js-image-center {
        left: 0 !important;
        top: auto !important;
        bottom: 0 !important;
    }

    .parallax-js-image-background {
        height: 300px !important;
        max-width: 100%;
        max-height: 50vh !important;
    }
}

.hide-parallax {
    opacity: 0 !important;
    transition: opacity 1s, left 3s;

    left: -30% !important;
    top: auto !important;
    bottom: 0 !important;
    max-width: 100%;
}

.smol-girl {
    height: 65%;
}

.smol-boy {
    height: 65%;
}

.big-boy {
    height: 65%;
}

.not-smol-girl {
    height: 65%;
}

@media (max-width: 600px) {

    .smol-girl {
        height: 85%;
    }
    
    .smol-boy {
        height: 85%;
    }
    
    .big-boy {
        height: 85%;
    }
    
    .not-smol-girl {
        height: 85%;
    }
}

sup {
    vertical-align: text-top;
    position: relative;
}
.video-image-wrapper {
    position: relative;
}

.video-image-wrapper:hover {
    cursor: pointer;
}

.play-button {
    position: absolute;

    top: calc(50% - 46.5px);
    left: calc(50% - 41px);
}

.video-banner {
    width: 100%;
}

.youtube-iframe-wrapper {
    width: 100%;
    position: relative;
    padding-top:56.25%
}

.youtube-iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
.home-wrapper {
    text-align: center;
}

.mobile-manta-ray-container {
    position: relative;
    text-align: center;
    overflow-x: hidden;
}

.mobile-manta-ray-container .parallax-js-image-center {
    max-width: 100%;
}

.manta-ray-image {
    min-width: 100vw;
    min-height: 100vh;
}

.manta-ray-heading {
    position: relative;
    width: 98%;
    padding-top: 0;
}

.desktop-banner {
    color: #fff;
    text-shadow: #000 0px 0px 8px;
    text-shadow: 3px 3px black;
    font-size: 60px;
}

.mobile-banner {
    font-size: 25px;
    color: #fff;
    text-shadow: #000 0px 0px 8px;
    text-shadow: 3px 3px black;
}

.black-box {
    background-color: rgba(0,0,0,.5);
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 25px;
}

.manta-ray-heading-2 {
    position: absolute;
    top: 65%;
    width: 100%;
}

.parent-request {
    font-size: 18px;
}

.desktop-manta-ray-container {
    position: relative;
    top: -50px;
    margin-bottom: -50px;
    height: 90vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.game-scroller-wrapper {
    width: 25%;
    margin: auto;
}


.bottom-padding {
    padding-bottom: 1em;
}

.homepage-review-wrapper {
    padding-bottom: 1em;
}

.padded{
    padding:10px;
    color:white
}

.spanning{
    width:100%;
    text-align: center;
    box-sizing: border-box;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
}
.warning-gradient{
    background: linear-gradient(to bottom, #FFEB3B , #FFC107);
    padding: 15px;

    cursor: pointer;
    color:black;
    text-shadow: none;
}
.warning-gradient:hover{
    background: none;
    background-color: rgb(223, 167, 0);
}

.stick-to-bottom{
    position:fixed;
    bottom:0;
    z-index: 2001;
}
.homeBackdrop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background-color: #33333395;
}
.homeBackdrop1 {
    display:none;
}
.homePositionWrapper{
    width: 85%;
    height: 100%;
    position: fixed;
    top: 10%;
    left: 6%;
    padding: 1% 25% 1% 25%;
    box-sizing: border-box;
}
.homeText1{
    width: 100%;
    position:relative;
    height: 33%;
    display: block;
    box-sizing: border-box;
    padding: 5% 1% 3% 1%;
    font-size: 16pt;
}
.homeText2{
    width: 100%;
    position:relative;
    height: 33%;
    display: block;
    box-sizing: border-box;
    padding: 3% 1% 3% 1%;
    font-size: 16pt;
}
.blueText{
    color: #0098da;
}
.homeImage{
    width:75%;
    position:relative;
    display:block;
    padding: 0 0 0 12.5%;
}
.homeMain{
    position: relative;
    display:block;
    background-color: #000000ee;

    text-align: center;

    box-sizing: border-box;
    border: 2px solid #333333;
    border-radius: 5px;

    color: #ffffff;
    border: 2px solid #0098da;

    border-radius: 5px;
}
.homeCloseButton{
    position: absolute;
    top:2px;
    right:2px;
    width:1.1em;
    height: 1.1em;

    text-align: center;

    background-color: #0098da;
    color: #ffffff;

    border: 1px solid #cccccc;
    padding:3px;
    border-radius: 5px;

    box-shadow: 2px 2px 3px 1px #111111;

}
.homeCloseButton:hover{
    background-color: #00abf5;
    box-shadow: 1px 1px 3px 0px #111111;
}

@media(max-width:1000px){
    .homeWrapper{
        padding:0px;
    }
}
.extra-life-wrapper {
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.t-shirt-iframe {
    width: 100%;
    height: 550px;
    margin-bottom: 2em;
}

.extra-life-sponsor {
    padding-top: 2em;
    padding-bottom: 2em;
    max-width: 80%;
}

.extralife-parallax {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.logo-parallax {
    top: auto !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 40%;
}

.parallax-divider {
    background: linear-gradient(90deg, #3b3b3b, #111111);
}

.extra-logo {
    top: 0 !important;
    left: 0 !important;
    width: 50%;
}

.hour-parallax {
    top: 45% !important;
    left: 0 !important;
    width: 40% !important;
}

.sponsor-hide {
    opacity: 0;
    transition: 0.5s;
}

.sponsor-show {
    opacity: 1;
    transition: 0.5s;
}

.sponsor-container {
    text-align: center;
    vertical-align: middle;
    height: 20%;
}

.sponsor-wrapper {
    height: 100%;
    width: calc(100% / 3);
    vertical-align: middle;
    display: inline-block;
}

.sponsor-padding-wrapper {
    vertical-align: middle;
    height: calc(100% - 2em);
    margin: 1em;
}

.sponsor-image {
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
}

@media (max-width: 1200px) {
    .t-shirt-iframe {
        height: 620px;
    }
}

.extra-life-section-wrapper {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
}

.extra-life-section-text-padding {
    margin-top: 1em;
    margin-bottom: 1em;
}

.extra-life-section-left-right-padding {
    margin-left: 6em;
    margin-right: 6em;
}

.extra-life-larger-font {
    font-size: 90px;
}

.extra-life-mega-font {
    font-size: 110px;
}

.extra-life-ultra-font {
    font-size: 150px;
}

.extra-life-title {
    font-size: 55px;
}

.extra-life-spider-man-font {
    font-family: GoodTimes;
}

.extra-life-furore-font {
    font-family: Furore;
}

.spider-man-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.spider-man-container:hover {
    cursor: pointer;
}

.spider-man-letter-spacing {
    letter-spacing: 0.3em;
}

.spider-man-free-gradiant {
	color: #F3C50E;
}

.spider-man-red {
    color: #D42023;
}

.extra-life-subscript {
    font-size: 16px;
}

.asterisk-super-script {
    position: relative;
    vertical-align: top;
    font-size: 10px;
    top: 2px;
}

.masked-trevr {
    max-width: 50%;
    margin: 1em;
    margin-left: auto;
    margin-right: auto;
}

.small-super-script {
    position: relative;
    vertical-align: top;
    font-size: 40px;
    top: 20px;
}

.extra-small-super-script {
    position: relative;
    vertical-align: top;
    font-size: 20px;
    top: 20px;
}

.extra-life-image {
    box-shadow: 0 0 3px rgba(0,152,218,0.3);

    max-width: 100%;

    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 1em;
}

.extra-life-image-no-overflow {
    max-width: calc(100% - 1em);
    padding: 1em;
}

.add-stroke {
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: white;
}

.add-spider-man-pulse-animation {
    -webkit-animation: SpidermanPulse 2s infinite;
            animation: SpidermanPulse 2s infinite;
}

@-webkit-keyframes SpidermanPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(218, 0, 0, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes SpidermanPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(218, 0, 0, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.glow {
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }
  
  @-webkit-keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #0098da, 0 0 40px #0098da;
    }
    
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #39c4ff, 0 0 40px #39c4ff, 0 0 50px #39c4ff;
    }
  }
  
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #0098da, 0 0 40px #0098da;
    }
    
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #39c4ff, 0 0 40px #39c4ff, 0 0 50px #39c4ff;
    }
  }

.games-wrapper {
    padding-left: 4em;
    padding-right: 4em;
}

.mobile-games-wrapper {
    text-align: center;
}

.games-section {
    padding: 3em;
}

.mobile-game-section {
    padding-top: 1em;
    padding-bottom: 1em;
}


.game-title {
    font-size: 34px;
    padding-bottom: 1em;
}

.left-game-title {
    text-align: left;
}

.center-game-title {
    text-align: center;
}

.right-game-title {
    text-align: right;
}

.game-logo {
    text-align: center;
    display: inline-block;
    width: 30%;
    vertical-align: middle;
}

.game-logo-img {
    max-width: 100%;
    max-height: 100%;
}

.game-body-gallery {
    text-align: center;
}

.game-body-gallery img {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    width: 320px;
    height: 202px;
    margin: 1em;
    box-shadow: 0px 0px 5px #fff;
}

.mobile-gallery img {
    padding-bottom: 1em;
}

.game-description {
    display: inline-block;
    width: 70%;
    vertical-align: middle;
}

.game-description-padding {
    padding: 2em;
}

.mobile-game-description {
    padding-top: 1em;
}
.lightbox-image-container {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.lightbox-image-wrapper {
    background-color: rgba(63, 63, 63, 0.6);
    text-align: center;
    width: 100%;
    height: 100%;
}

.lightbox-image {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50vh;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);

    width: 720px;
    height: auto;
}
.card-wrapper {
    font-size: 18px;
}

.card-wrapper input {

    background: #4a4a4a80;
    width: 50%;
    height: 100%;
    padding: 0;

    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    height: 34px;
    color: white;

    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.card-wrapper input::-webkit-input-placeholder {
    color: #858585;
}

.card-wrapper input::-moz-placeholder {
    color: #858585;
}

.card-wrapper input:-ms-input-placeholder {
    color: #858585;
}

.card-wrapper input::-ms-input-placeholder {
    color: #858585;
}

.card-wrapper input::placeholder {
    color: #858585;
}

.card-button-browser-font {
    font-size: 18px;
    padding-left:1em;
    padding-right:1em;
}
  
.card-button-mobile-font {
    font-size: 18px;
    width: 80%;
}

.card-message-font {
    font-size: 14px;
}

.rel {
    position: relative;
}

.trevr-robit-parallax {
    width: 35%;
    position: absolute;
    right: auto !important;
    left: 0 !important;
    top: auto !important;
    bottom: 0 !important;
}

.green-robit-parallax {
    position: absolute;
    right: 0 !important;
    left: auto !important;
    top: auto !important;
    bottom: 0 !important;
    width: 45%;
}

.text-overlay-parallax {
    height: 100%;
}

.loyalty-wrapper {
    text-align: left;
}

.loyalty-wrapper li {
    list-style-type: decimal;
}

.loyalty-wrapper li li {
    list-style-type: disc;
}

.loyalty-image {
    box-shadow: 0 0 3px rgba(0,152,218,0.3);

    max-width: 100%;

    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 1em;
}

.email-wrapper {
    font-size: 18px;
}

.email-wrapper input {

    background: #4a4a4a80;
    width: 50%;
    height: 100%;
    padding: 0;

    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    height: 34px;
    color: white;

    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.email-wrapper input::-webkit-input-placeholder {
    color: #858585;
}

.email-wrapper input::-moz-placeholder {
    color: #858585;
}

.email-wrapper input:-ms-input-placeholder {
    color: #858585;
}

.email-wrapper input::-ms-input-placeholder {
    color: #858585;
}

.email-wrapper input::placeholder {
    color: #858585;
}

.email-button-browser-font {
    font-size: 18px;
    padding-left:1em;
    padding-right:1em;
}
  
.email-button-mobile-font {
    font-size: 18px;
    width: 80%;
}

.email-message-font {
    font-size: 14px;
}

.account-video{
    width:60vw;
    margin-left: auto;
    margin-right: auto;
}
.faq-list li {
    list-style-type: decimal;
    list-style-position:inside;
}

.faq-wrapper {
    padding: 1em;
}

.faq-browser-wrapper {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.faq-heading {
    font-size: 20px;
    padding-top: 1em;
    padding-bottom: 1em;
}
.about-wrapper {
    font-size: 18px;
    text-align: center;
    overflow-wrap: break-word;
}

.about-wrapper li {
    text-align: left;
}

.location-map-wrapper {
    height: 80vh;
    width: 90%;
    padding: 1em;
    margin-left: auto;
    margin-right: auto;
    text-shadow: none;
}

.map-wrapper { 
    height: 90%;
}
.location-pin-wrapper {
    position: relative;
}

.location-pin-modal {
    position: absolute;
    top: -64px;
    -webkit-transform: translate(-50%, -100%);
        -ms-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    background-color: black;
    padding: 1em;
    white-space: nowrap;
    font-size: 18px;
}

.show-pin-modal {
    opacity: 1;
    visibility: show;
    transition: 1s;
}

.hide-pin-modal {
    opacity: 0;
    visibility: hidden;
    transition: 1s;
}

.location-pin {
    -webkit-transform: translate(-50%, -100%);
        -ms-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
}
.contest-dropdown-wrapper {
    background-color: rgba(75, 75, 77, 0.3);
    border: 1px solid gray;
}

.contest-dropdown-heading {
    font-size:34px;
    text-align: left;
    color: #0098da;
    padding: 1em;
}

.contest-dropdown-heading:hover {
    cursor: pointer;
}

.show-dropdown {
    max-height: 85vh;
    overflow-y : auto;
    transition: 0.5s;
}

.hide-dropdown {
    max-height: 0px;
    overflow-y: hidden;
    transition: 0.5s;
}
.coverage-index-button {
    background-color: #0098DA;
    color: white;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 1em;
    transition: 0.5s;
    font-family: 'Days One', sans-serif;
}

.coverage-index-button:hover {
    cursor: pointer;
    background-color: #007bb0;
    transition: 0.5s;
}

.coverage-index-button:disabled {
    background-color:#969696;
    color: #4d4d4d;
    transition: 0.5s;
    cursor: default;
    text-shadow: none;
}



.panel-wrapper {
    display: -ms-flexbox;
    display: flex;
}
.coverage-preview-wrapper {
    padding: 1em;
    margin: 1em;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid white;
}
.coverage-preview-wrapper:hover {
    cursor: pointer;
}

.coverage-browser-wrapper { 
    width: calc(100% / 3);
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: top;
}

.pressroom-video {
    margin: 1em;
    box-shadow: 0 0 3px rgba(0,152,218,0.3);

}

.desktop-pressroom-video {
    display: inline-block;
    width: calc(100% / 3);
}

.pressroom-post img {
    max-width: 100%;
    height: auto;
}

.parallax-pressroom-header {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/3297ac15-press_room.png");
    height: 60vh;
}

.book-now-popup {
    display: none;
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;

    bottom: 6px;
    left: 0;
    position:fixed;

    padding: .5em;
    padding-left: 1em;
    padding-right: 1em;
    margin: 1em;
    border-radius: 15px;
    z-index: 7;
    font-size: 18px;
    font-family: 'Days One', sans-serif;
    -webkit-animation: BookNowPulse 3s infinite;
            animation: BookNowPulse 3s infinite;
}

.book-now-popup:hover {
    cursor: pointer;
    transition: 0.5s;
    background: linear-gradient(0deg, #00577d, #00577d);
}

.book-now-popup a {
    color:white;
}

@media (max-width: 1500px){
    .book-now-popup{
        display: inline;
    }
}

@-webkit-keyframes BookNowPulse {

    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

@keyframes BookNowPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}
.cookie-popup-wrapper {
    position: fixed;
    bottom: 2em;
    z-index: 8;
    width: 100vw;
}

.cookie-popup {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    text-align: center;
    background-color: #1e1e1e;
    border-radius: 10px;
    border: 1px solid #0098DA;
}

.cookie-text {
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 1em;
}

@media (max-width: 930px) {
    .cookie-popup {
        width: 95%;
    }
}

.unsupported-browser-modal-wrapper {
    position: fixed; /* Stay in place */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
}

.unsupported-browser-modal-wrapper:hover {
    cursor: pointer;
}

.unsupported-browser-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    font-size: 1.5em;
    color: black;
    text-shadow: none;
    text-align: center;

    background-color: rgba(255,255,255,0.9);
    margin: auto;
    padding: 20px;
    width: 20%;
    white-space: normal;
    border: 3px solid #0098DA;
    box-sizing: border-box;
    border-radius: 16px;
}

.unsupported-browser-modal:hover {
    cursor: auto;;
}

.close-button:hover{ 
    cursor: pointer;
    background-color: #32b5ee;
    box-shadow: none;
}

.close-button {
    text-align: center;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    float: right;
    background-color: #0098da;
    color: white;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border-color: #ccc;
    border-radius: 9px;
    box-shadow: 1px 1px 2px 0px #545454;
}

@media (max-width: 1000px) {
    .unsupported-browser-modal {
        width: 80%;
    }
}


.show404-error-code {
    font-size: 128px;
    text-align: center;
}

.show404-sub-heading {
    font-size: 64px;
    text-align: center;
}

.modal {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Overlay effect: translucent background: black w/ partial opacity */
    z-index: 1000; /* Overlay effect: positioned over other containers */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: fixed; /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    padding-top: 80px; /* Location of the content container */
}
/* Modal content */
.modal-content {
    position: relative;
    background-color: white;
    width: 40%;
    min-width: 400px; /* Width in proportion to its parent container*/
    max-width: 640px; /* Max width where it stops expanding */
    margin: auto;
    padding: auto; /* Auto margin according to the element width */
    margin-top: 40px;
    border-radius: 10px;
    border-color: #333333;
    color: black;
    text-shadow: "none";
    padding-bottom: 30px;
    overflow-y: auto;/* Optional. Rounds container corners */
}
/* Close button */

.center {
    position: absolute;
    left: 50%;
    top: 35%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }

.modal-header {
    padding: 10px;
    background-color: #333333;
    border-color: #333333;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: white;
    text-align: "center";
}

.close {
   margin-top: -1%;
   color: white;
   float: right; /* Positioned to the right of the parent container whichever size it is */
   font-size: 25px;
   font-weight: bold;
}
.close:hover, 
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}


option {
    font-family: sans-serif;
}

option:hover{
    background-color: rgba(0, 0, 0, 0.1);
}

.override-open-select-label {
    position: absolute !important;
    text-align: left !important;
    vertical-align: center !important;
    left: 0 !important;
    top: -10px !important;
    pointer-events: none;
}

.open-select-select {
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 0px solid #aaa;
	/* box-shadow: 0 1px 0 1px rgba(0,0,0,.04); */
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.open-select-select::-ms-expand {
	display: none;
}
.open-select-select:hover {
	border-color: #888;
}
.open-select-select:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.open-select-select option {
	font-weight:normal;
}

.override-left-padding > .MuiFormControl-root > .MuiInputBase-root > #email {
    padding: 6px 0px 7px .6em !important;
}
.override-left-padding > .MuiFormControl-root > .MuiInputBase-root > #first_name {
    padding: 6px 0px 7px .6em !important;
}
.override-left-padding > .MuiFormControl-root > .MuiInputBase-root > #last_name {
    padding: 6px 0px 7px .6em !important;
}

.superscript-font {
    font-size: 36px;
}

.annivrsary-heading {
    font-size: 42px;
}

.annivrsary-title {
    font-size: 86px;
    margin: 0.1em 0;
}

.annivrsary-stroke {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: white;
}

.box-shadow-image {
    box-shadow: -2px 2px 8px white;
    width: 100%;
    margin: 0.5em 0;
}

.margin {
    margin: 2em 0;
}

.small-margin {
    margin: 1em 0;
}
.seniors-heading {
    font-size: 46px;
    padding-top: 2rem;
}

.seniors-heading-2 {
    font-size: 36px;
    padding-top: 1rem;
}

.seniors-generic-text {
    font-size: 20px;
    padding-top: 0.75rem;
}

.seniors-generic-text-2 {
    font-size: 24px;
    padding-top: 0.75rem;
}

.seniors-fine-print {
    font-size: 14px;
    padding-top: 0.75rem;
}

.seniors-blue-text {
    color: #0098da;
}
.heading {
    font-size: 60px;
    padding-top: 2rem;
    text-decoration: underline;
}

.heading-2 {
    font-size: 36px;
    padding-top: 1rem;
}

.province-heading {
    font-size: 44px;
    padding-top: 1rem;
    color: #0098da;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.location-heading {
    font-size: 32px;
    padding-top: 1rem;
}

.location-text {
    font-size: 24px;
    padding-top: 0.25rem;
}

.generic-text {
    font-size: 24px;
    padding-top: 0.75rem;
}

.generic-text-2 {
    font-size: 24px;
    padding-top: 0.75rem;
}

.generic-text-3 {
    font-size: 20px;
    padding-top: 0.75rem;
}

.fine-print {
    font-size: 14px;
    padding-top: 0.75rem;
}

.bottom-padding {
    padding-bottom: 0.75em;
}

.top-padding {
    padding-top: 0.75em;
}

.top-padding-2 {
    padding-top: 2em;
}

.blue {
    color: #0098da;
    font-size: 46px;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    font-weight: bold;
}
.escape-title{
    font-size: 4em;
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
}

.top-image-section{
    display: inline-block;
    width: 50%;
    padding-left: 25%;
    padding-right: 25%;
    padding-bottom: 2em;
}

.escape-paragraph-section{
    font-size: 2em;
    text-align: center;
    margin-left: 5em;
    margin-right: 5em;
    padding-bottom: 2em;
}

.escape-paragraph {
    width: 80%;
    padding: 0 10%;
}

.escape-button{
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 1em;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-top: 1em;
    margin-top: 2em;
    font-family: 'Days One', sans-serif;
}

.escape-button:hover {
    background: linear-gradient(0deg, #0098da, #0098da);
    color: white;
    transition: 0.5s;
    
    -webkit-animation: PulseAndCollapse 3s infinite;
    
            animation: PulseAndCollapse 3s infinite;
}




.escape-section{
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 2em;
    margin-bottom: 3em;
}

.escape-sub-section{
    display: inline-block;
    width: 50%;
    text-align: center;
    vertical-align:middle;
    padding: 1em 0;
}

.escape-sub-section2{
    width: 80%;
    padding-left: 10%;
}

.escape-sub-section3{
    width: 80%;
    padding-left: 10%;
}

.escape-sub-section4{
    position: relative;
    padding-bottom: 50%;
    padding-top: 30px;
    height: 0;
}

.escape-sub-section-ytl{
    display: inline-block;
    width: 50%;
    text-align: center;
    vertical-align:middle;
    padding: 1em 0;
}

.escape-sub-section-ytl2{
    display: none;
}


.escape-info-section{
    display: inline-block;
    width: 50%;
    text-align: center;
    vertical-align:middle;
}

.escape-heading{
    display: block;
    font-size: 3rem;
    text-align: center;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
    margin-bottom: 1em;
}

.escape-sub-heading-section{
    display: inline-block;
    text-align: left;
    margin-left: 3em;
}

.escape-sub-heading{
    display: inline-block;
    width: 49%;
    font-size: 1.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

.escape-sub-heading2{
    display: inline-block;
    white-space: nowrap;
    width: 49%;
    font-size: 1.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

.escape-sub-heading3{
    display: inline-block;
    white-space: nowrap;
    width: 49%;
    font-size: 1.15em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

.escape-image-section{
    display: inline-block;
    width: 90%;
    vertical-align: middle;
}

.escape-image-section2{
    display: inline-block;
    width: 75%;
    vertical-align: middle;
    padding-left: 4em;
}

.escape-image{
    box-shadow: 0px 0px 25px 0.5px #fff;
}

.escape-description{
    width: 85%;
    display: inline-table;
    font-size: 1.35em;
    padding-top: 1.85em;
}

.yt-iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}




@media (max-width:1400px){
    .escape-title{
        font-size: 3em;
        text-align: center;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
    }
    
    .escape-paragraph-section{
        width: 100%;
        font-size: 1.25em;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 2em;
    }

    
    .escape-section{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        text-align: center;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
    }

    .escape-sub-section{
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        text-align: center;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
    }

    .escape-sub-section2{
        width: 100%;
        padding-left: 0;
    }

    .escape-sub-section3{
        width: 80%;
        padding-left: 0;
    }

    .escape-sub-section-ytl{
        display: none;
    }

    .escape-sub-section-ytl2{
        display: inline-block;
        width: 70%;
        text-align: center;
        vertical-align:middle;
        padding: 1em 0;
    }


    .escape-info-section{
        display: inline-block;
        width: 100%;
        text-align: center;
        vertical-align:middle;
    }

    .escape-heading{
        display: block;
        width: 90%;
        font-size: 2.15rem;
        text-align: center;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
        margin-bottom: 0.5em;
    }
    
    .escape-sub-heading-section{
        display: grid;
        width: 60%;
        text-align: left;
        margin: 0;
        margin-bottom: 2.5em;
        padding-left: 25%;
        padding-right: 15%;
    }

    .escape-sub-heading{
        display:inline-block;
        width: 50%;
        grid-column: 1;
        font-size: 1.15em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        padding-left: 2.25em;
    }

    .escape-sub-heading2{
        display:inline-block;
        width: 50%;
        grid-column: 2;
        font-size: 1.15em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        padding-left: 0.5em;
    }

    .escape-sub-heading3{
        display: inline-block;
        white-space: nowrap;
        width: 49%;
        font-size: .9em;
        padding-top: 0.6em;
        padding-bottom: 0.25em;
        padding-left: 0.5em;
    }

    .escape-image-section{
        display: inline-block;
        width: 75%;
        vertical-align: middle;
    }

    .escape-image-section2{
        display: inline-block;
        width: 75%;
        vertical-align: middle;
        padding-left: 0;
    }

    .escape-description{
        width: 80%;
        display: inline-table;
        font-size: 1em;
        padding-top: 1.85em;
    }
}



@media (max-width:500px){
    .escape-sub-heading-section{
        display: grid;
        width: 100%;
        text-align: left;
        margin: 0;
        margin-bottom: 2.5em;
        padding-left: 0;
        padding-right: 0%;
    }
}
.hs-title{
    font-size: 3.5em;
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
}

.hs-title2{
    font-size: 3em;
    text-align: center;
    padding-bottom: 1em;
    color: #ffffff;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: #0098da;
}
 
.top-image-section{
    display: inline-block;
    width: 40%;
    padding-left: 30%;
    padding-right: 30%;
    padding-bottom: 2em;
}
 
.hs-paragraph-section{
    font-size: 1.5em;
    text-align: center;
    margin-left: 3em;
    margin-right: 3em;
    padding-bottom: 2em;
}
 
.hs-paragraph {
    width: 90%;
    padding: 0 5%;
}
 
.hs-button{
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 1em;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-top: 1em;
    margin-top: 2em;
    font-family: 'Days One', sans-serif;
}
 
.hs-button:hover {
    background: linear-gradient(0deg, #0098da, #0098da);
    color: white;
    transition: 0.5s;
   
    -webkit-animation: PulseAndCollapse 3s infinite;
   
            animation: PulseAndCollapse 3s infinite;
}
 
 
 
 
.hs-section{
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 2em;
    margin-bottom: 3em;
}

.hs-section2{
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 2em;
    margin-bottom: 3em;
}
 
.hs-sub-section{
    display: inline-block;
    width: 50%;
    color: #FFF;
    -webkit-text-stroke-width: 0.05em;
    -webkit-text-stroke-color: #0098da;
    text-shadow: 0.1em 0.1em 0.1em #000;
    text-align: center;
    vertical-align:middle;
    padding: 1em 0;
}
 
.hs-sub-section2{
    width: 80%;
    padding-left: 10%;
}
 
.hs-sub-section3{
    width: 80%;
    padding-left: 10%;
}
 
.hs-sub-section4{
    position: relative;
    padding-bottom: 50%;
    padding-top: 30px;
    height: 0;
}

.hs-sub-section5{
    display: inline-block;
    width: 25%;
    vertical-align:middle;
}
 
.hs-sub-section-ytl{
    display: inline-block;
    width: 50%;
    text-align: center;
    vertical-align:middle;
    padding: 1em 0;
}
 
.hs-sub-section-ytl2{
    display: none;
}
 
 
.hs-info-section{
    display: inline-block;
    width: 50%;
    color: white;
    -webkit-text-stroke-width: 0.07em;
    -webkit-text-stroke-color: #0098da;
    text-shadow: 0.1em 0.1em 0.1em #000;
    text-align: center;
    vertical-align:middle;
}
 
.hs-heading{
    display: block;
    font-size: 3rem;
    text-align: center;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
    margin-bottom: 1em;
}

.hs-heading2{
    display: block;
    font-size: 3rem;
    text-align: center;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
    margin-bottom: 0.25em;
}

.hs-heading3{
    display: block;
    width: 100%;
    font-size: 3rem;
    text-align: center;
    color: #0098da;
    -webkit-text-stroke-width: 0.03em;
    -webkit-text-stroke-color: white;
    margin-bottom: 0.25em;
}
 
.hs-sub-heading-section{
    display: inline-block;
    text-align: left;
    margin-left: 3em;
}
 
.hs-sub-heading{
    display: inline-block;
    width: 49%;
    font-size: 2.25em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}
 
.hs-sub-heading2{
    display: inline-block;
    white-space: nowrap;
    width: 49%;
    font-size: 2.25em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}
 
.hs-sub-heading3{
    display: inline-block;
    width: 49%;
    font-size: 2em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    margin-top: 1em;
}

.hs-sub-heading4{
    display: inline-block;
    width: 95%;
    font-size: 1.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    margin-top: 0.5em;
}

.hs-sub-heading5{
    display: inline-block;
    width: 100%;
    font-size: 2em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    margin-top: 0.5em;
}
 
.hs-image-section{
    display: inline-block;
    width: 90%;
    vertical-align: middle;
}
 
.hs-image-section2{
    display: inline-block;
    width: 75%;
    vertical-align: middle;
    padding-left: 4em;
}
 
.hs-image{
    box-shadow: 0px 0px 25px 0.5px #0098da;
}
 
.hs-description{
    width: 85%;
    display: inline-table;
    font-size: 1.35em;
    padding-top: 1.85em;
}

.hs-description2{
    width: 85%;
    display: inline-table;
    font-size: 1.35em;
}

.hs-description3{
    width: 85%;
    font-size: 1.35em;
    text-align: left;
    margin-top: 1em;
}
 
.yt-iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hs-blue{
    display: inline;
    color: #0098DA;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;   
}

.hs-margin{
    margin-top: 3em;
}

.hs-pulse-animation {
    -webkit-animation: HSPulse 3s infinite;
            animation: HSPulse 3s infinite;
}

@-webkit-keyframes HSPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes HSPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 152, 218, 0.8);
    }
    25% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    50%,
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.hs-button {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 1.75em;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin: 2em 0;
    font-family: 'Days One', sans-serif;
}

.hs-button:hover { 
    background: linear-gradient(0deg, #00577d, #00577d);
    cursor: pointer;
}

.hs-button2 {
    background: linear-gradient(0deg, #00577d, #0098DA);
    color: white;
    font-size: 1em;
    text-align: center;
    border-radius: 8px;
    border: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin: 1em 0;
    font-family: 'Days One', sans-serif;
}

.hs-button2:hover { 
    background: linear-gradient(0deg, #00577d, #00577d);
    cursor: pointer;
}
 
 
 
 
@media (max-width:1400px){
    .hs-title{
        font-size: 2.5em;
        text-align: center;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
    }

    .hs-title2{
        font-size: 2em;
        text-align: center;
        padding-bottom: 1em;
        color: #ffffff;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: #0098da;
    }
    
   
    .hs-paragraph-section{
        width: 100%;
        font-size: 1.25em;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 2em;
    }
 
   
    .hs-section{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        text-align: center;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
    }

    .hs-section2{
        display: block;
        width: 100%;
        height: 100%;
        margin-top: 2em;
        margin-bottom: 3em;
    }
 
    .hs-sub-section{
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        text-align: center;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
    }
 
    .hs-sub-section2{
        width: 100%;
        padding-left: 0;
    }
 
    .hs-sub-section3{
        width: 50%;
        padding-left: 0;
    }

    .hs-sub-section4{
        position: relative;
        padding-bottom: 50%;
        padding-top: 30px;
        height: 0;
    }
    
    .hs-sub-section5{
        display: inline-block;
        width: 25%;
        vertical-align:middle;
    }    
 
    .hs-sub-section-ytl{
        display: none;
    }
 
    .hs-sub-section-ytl2{
        display: inline-block;
        width: 50%;
        text-align: center;
        vertical-align:middle;
        padding: 1em 0;
    }
 
 
    .hs-info-section{
        display: inline-block;
        width: 50%;
        text-align: center;
        vertical-align:middle;
    }
 
    .hs-heading{
        display: block;
        width: 90%;
        font-size: 2.15rem;
        text-align: center;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
        margin-bottom: 0.5em;
    }

    .hs-heading2{
        display: block;
        width: 90%;
        font-size: 2.15rem;
        text-align: center;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
        margin-bottom: 0.25em;
    }
     
    .hs-heading3{
        display: block;
        width: 90%;
        font-size: 2.15rem;
        text-align: center;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
        margin-bottom: 0.25em;
    }
   
    .hs-sub-heading-section{
        display: grid;
        width: 60%;
        text-align: left;
        margin: 0;
        margin-bottom: 2.5em;
        padding-left: 25%;
        padding-right: 15%;
    }
 
    .hs-sub-heading{
        display:inline-block;
        width: 50%;
        grid-column: 1;
        font-size: 1.75em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        padding-left: 0.5em;
    }
 
    .hs-sub-heading2{
        display:inline-block;
        width: 50%;
        grid-column: 2;
        font-size: 1.75em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        padding-left: 0.5em;
    }
 
    .hs-sub-heading3{
        display: inline-block;
        white-space: nowrap;
        width: 49%;
        font-size: 1.75em;
        padding-top: 0.6em;
        padding-bottom: 0.25em;
    }

    .hs-sub-heading4{
        display: inline-block;
        width: 75%;
        font-size: 1.25em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        margin-top: 0.5em;
    }

    .hs-sub-heading5{
        display: inline-block;
        width: 100%;
        font-size: 1.75em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        margin-top: 0.5em;
    }
 
    .hs-image-section{
        display: inline-block;
        width: 75%;
        vertical-align: middle;
    }
 
    .hs-image-section2{
        display: inline-block;
        width: 75%;
        vertical-align: middle;
        padding-left: 0;
    }
 
    .hs-description{
        width: 80%;
        display: inline-table;
        font-size: 1.25em;
        padding-top: 1.85em;
    }

    .hs-description2{
        width: 80%;
        display: inline-table;
        font-size: 1.25em;
    }
     
    .hs-description3{
        width: 80%;
        font-size: 1.10em;
        text-align: left;
    }
    
    .hs-margin{
        margin-top: 2em;
    }
    
    .hs-button {
        background: linear-gradient(0deg, #00577d, #0098DA);
        color: white;
        font-size: 1.5em;
        text-align: center;
        border-radius: 8px;
        border: none;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin: 2em 0;
        font-family: 'Days One', sans-serif;
    }

    .hs-button2 {
        background: linear-gradient(0deg, #00577d, #0098DA);
        color: white;
        font-size: 0.90em;
        text-align: center;
        border-radius: 8px;
        border: none;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin: 1em 0;
        font-family: 'Days One', sans-serif;
    }
}
 
 
 
@media (max-width:500px){
    .hs-title{
        font-size: 2em;
        text-align: center;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        color: #0098da;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: white;
    }

    .hs-title2{
        font-size: 1.75em;
        text-align: center;
        padding-bottom: 1em;
        color: #ffffff;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: #0098da;
    }

    .hs-paragraph-section{
        width: 100%;
        font-size: 1em;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 2em;
    }
    
    .hs-sub-heading-section{
        display: grid;
        width: 100%;
        text-align: left;
        margin: 0;
        margin-bottom: 2.5em;
        padding-left: 0;
        padding-right: 0%;
    }

    .hs-info-section{
        display: inline-block;
        width: 60%;
        text-align: center;
        vertical-align:middle;
    }

    .hs-section{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        text-align: center;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
        margin-bottom: 0.5em;
    }

    .hs-section2{
        display: block;
        width: 100%;
        height: 100%;
        margin-top: 0;
        margin-bottom: 3em;
    }

    .hs-description{
        width: 80%;
        display: inline-table;
        font-size: 1em;
        padding-top: 1.85em;
    }

    .hs-description2{
        width: 80%;
        display: inline-table;
        font-size: 1em;
    }
     
    .hs-description3{
        width: 80%;
        font-size: 1em;
        text-align: left;
    }

    .hs-sub-heading3{
        display: block;
        width: 60%;
        font-size: 1.75em;
        padding-top: 0.6em;
        padding-bottom: 0.25em;
        padding-left: 0;
        white-space: pre-line;
    }

    .hs-sub-heading4{
        display: inline-table;
        width: 90%;
        font-size: 1.25em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        margin-top: 0.5em;
    }

    .hs-sub-heading5{
        display: inline-block;
        width: 100%;
        font-size: 1.25em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        margin-top: 0.5em;
    }

    .hs-sub-section5{
        display: inline-block;
        width: 50%;
        vertical-align:middle;
    } 

    .hs-sub-section-ytl2{
        display: inline-block;
        width: 60%;
        text-align: center;
        vertical-align:middle;
        padding: 1em 0;
    }

    .top-image-section{
        display: inline-block;
        width: 55%;
        padding-left: 20%;
        padding-right: 30%;
        padding-bottom: 2em;
    }

    .hs-image-section{
        display: inline-block;
        width: 85%;
        vertical-align: middle;
    }
 
    .hs-image-section2{
        display: inline-block;
        width: 85%;
        vertical-align: middle;
        padding-left: 0;
    }

    .hs-button {
        background: linear-gradient(0deg, #00577d, #0098DA);
        color: white;
        font-size: 1.25em;
        text-align: center;
        border-radius: 8px;
        border: none;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin: 2em 0;
        font-family: 'Days One', sans-serif;
    }

    .hs-button2 {
        background: linear-gradient(0deg, #00577d, #0098DA);
        color: white;
        font-size: 0.75em;
        text-align: center;
        border-radius: 8px;
        border: none;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin: 1em 0;
        font-family: 'Days One', sans-serif;
    }
}
